.notification-details-page__main {
  .session-heading-right-content {
    display: flex;
    .filterData---notification {
      margin-right: 1rem;
    }
    .log-filter-block {
      display: flex;
      padding: 0.95rem;
      .log-filter-icon {
        display: flex;
        align-items: center;
        .log-filter-label {
          padding-left: 0.5rem;
          font-size: 0.875rem;
          font-weight: 600;
        }
      }
      .log-arrow__icon {
        padding-left: 0.5rem;
        color: $primary-btn-color;
        margin-left: auto;
      }
    }
    .filter-block {
      display: flex;
      padding: 0.75rem;
      .filter-icon {
        display: flex;
        align-items: center;
        .filter-label {
          padding-left: 0.5rem;
          font-size: 0.875rem;
          font-weight: 600;
        }
      }
      .arrow__icon {
        color: $primary-btn-color;
        margin-left: auto;
      }
    }
    .filter-dropdown {
      min-width: 12rem;
      margin-right: 1rem;
      @media screen and (max-width: 1024px) {
        min-width: 10rem;
      }
      @media screen and (max-width: 768px) {
        min-width: 8rem;
      }
    }
    .item {
      font-size: 0.75rem;
      font-weight: 300;
      line-height: 0.941rem;
    }
    .underline {
      border-bottom: 0.2rem solid #3d4e5f;
      width: 13rem;
      margin-left: 1.2rem;
      padding-top: 1rem;
    }
    .apply-content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.6rem;
      .apply-label {
        color: $primary-btn-color;
        font-size: 0.875rem;
        font-weight: 700;
      }
    }
    .log-search-excel-block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.05rem;
      .log-search-box {
        margin-left: 1rem;
        .log-search-button {
          border: none;
          cursor: pointer;
          border-radius: 5px;
          padding: 0.92rem 1.2rem;
          font-size: 0.9rem;
        }
      }
    }
  }
  .notification-number {
    background: #be210b;
    border-radius: 7px;
    height: 1.125rem;
    width: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    .notification-text {
      font-weight: 600;
      font-size: 0.625rem;
      color: $input-value-color;
    }
  }
  .dataTimePicker-floating-label-input-group {
    column-gap: 1rem;
  }
  .loader--block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1028px) {
  .session-heading-right-content {
    display: flex;
    flex-direction: column-reverse;
    .filterData---notification {
      margin-top: 1rem;
    }
  }
}

// .notification-details--tab {
//   .tab-block {
//     .tab-nav-block {
//       .tab-nav__item {
//         width: 100%;
//       }
//       .notification--info {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         .notification-name {
//           margin-right: 0.5rem;
//         }
//         .notification-number {
//           background: #be210b;
//           border-radius: 7px;
//           height: 1.125rem;
//           width: 1.125rem;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           .notification-text {
//             font-weight: 600;
//             font-size: 0.625rem;
//             color: $input-value-color;
//           }
//         }
//       }
//     }
//   }
// }
