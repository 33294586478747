.customer-management-page__main {
  .customer-details--block {
    margin-bottom: 1.5rem;
    .customer-details-search--block {
      .date-picker {
        margin-bottom: 1rem;
      }
      .customerList-add-boxButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-bottom: 1rem;
        .customerList-add-box-Btn {
          padding: 0.8rem 1.2rem;
          margin-bottom: 0.5rem;
        }
      }
    }
    .customer-details-inner {
      .customer-details-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        .customer-details-wrapper {
          margin-bottom: 1.5rem;
          box-sizing: border-box;
          .customer-info--box {
            display: flex;
            border: 1px solid rgba(255, 255, 255, 0.1);
            padding: 0.5rem;
            border-radius: 8px;
            .customer-info-icon {
              margin-right: 1rem;
              font-size: 2rem;
              padding: 1.3rem;
              box-sizing: border-box;
              border-radius: 8px;
              text-align: center;
              align-items: center;
              display: flex;
              justify-content: center;
            }

            .customer-info--wrapper {
              align-items: flex-start;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;

              .total-customer {
                font-style: normal;
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 1.25rem;
              }

              .info-text {
                font-style: normal;
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 0.938rem;
              }
            }
          }
        }
      }
    }
  }
  .new-customer--list {
    margin-bottom: 1.5rem;
    .customer-text {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
      color: $input-value-color;
      margin-bottom: 1rem;
    }
    .customer-list-table {
      .list-icon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: #5f7384;
        cursor: pointer;
      }
      .data-list--cell {
        width: 4rem;
        .list-items {
          white-space: pre-wrap;
          p {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
      .customer-name-list {
        display: flex;
        align-items: center;
        .customer-avatar {
          height: 1.7rem;
          width: 1.7rem;
        }
      }
      .customerList-table-editIcon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: #3c7cdd;
        cursor: pointer;
      }
      .customerList-table-deleteIcon {
        font-size: 1.2rem;
        color: #be210b;
        cursor: pointer;
      }
      .customer-list-icon {
        cursor: pointer;
      }
    }
  }
  .users-group-list--block {
    .user-group-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .user-group-text {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        color: $input-value-color;
      }
      .upload-file-block {
        .upload-file--input {
          display: none;
        }
        .upload-file-inner {
          cursor: pointer;
          .upload-file-text {
            font-style: normal;
            font-weight: bold;
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: #e12f17;
            padding-left: 0.8rem;
          }
        }
      }
    }
    .users-group-list {
      .users-group-list-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        color: $input-value-color;
        background-color: $primary-bg-color;
        font-size: 0.875rem;
        white-space: nowrap;
        border-bottom: 1px solid #29343e;
        text-align: left;
        vertical-align: middle;
        .user-group--name {
          display: flex;
          align-items: center;
        }
        .list-text {
          cursor: pointer;
          padding-left: 1rem;
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: $input-value-color;
        }
        .users-group-list-icon {
          cursor: pointer;
          color: #be210b;
          font-size: 1.4rem;
          padding: 0.5rem;
        }
      }
    }
  }
}

// Form css
.customer-form-page__main {
  .customer-form-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #2e3841;
    .customer-header-name {
      font-size: 1.25rem;
    }
    .close___btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: black;
      }
    }
  }
  .customer-form-body__block {
    padding: 1rem 2rem;
    .customer-form--block {
      .cutomer-input--field {
        margin-left: 1rem;
      }
      .form-btn__block {
        text-align: right;
        margin-top: 1rem;
        .form_btn {
          padding: 0.8rem 1.7rem;
          font-weight: 500;
        }
        .cancel____btn {
          margin-right: 1rem;
          background-color: transparent;
          border-color: transparent;
          color: $primary-btn-color;
          &:hover {
            background-color: #36303a;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

//wallet Transaction
.wallet-transaction_main {
  .wallet-transaction-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #2e3841;
    .wallet-transaction-name {
      font-size: 1.25rem;
    }
    .close-btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: black;
      }
    }
  }
  .wallet-transaction_form {
    padding: 1rem 2rem;
    .wallet-transaction_button {
      text-align: right;
      .wallet-transaction-btn {
        padding: 0.8rem 1.7rem;
        font-weight: 500;
      }
      .cancel_btn {
        margin-right: 1rem;
        background-color: transparent;
        border-color: transparent;
        color: $primary-btn-color;
        &:hover {
          background-color: #36303a;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
