.form-check-input {
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
  margin-right: 0.8rem;
  background: $primary-bg-color;
  border: 1px solid #8d9dac;
  box-sizing: border-box;
  border-radius: 2px;
  &.form-check-input:checked {
    background-color: $primary-btn-color;
    border-color: unset;
  }
  &.form-check-input:focus {
    outline: 0;
    box-shadow: unset;
    border-color: #8d9dac;
  }
}
.form-check-label {
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.125rem;
  color: #ffffff;
}
