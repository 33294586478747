.billing-invoicing__main {
  .billing--box {
    margin-bottom: 1rem;
    .billing-content--box {
      .billing-info--box {
        display: flex;
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-sizing: border-box;
        border-radius: 8px;
        padding: 0.9rem;
        margin-bottom: 1rem;
        .billing-info-icon {
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-sizing: border-box;
          border-radius: 8px;
          padding: 1rem;
          > svg {
            color: #3c7cdd;
            font-size: 1.3rem;
            box-sizing: border-box;
          }
        }
        .billing-info--wrapper {
          display: flex;
          align-items: center;
          margin-left: 1rem;
          .billing-title {
            font-style: normal;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.563rem;
            color: $input-value-color;
            margin-right: 0.5rem;
          }
          .billing-subtitle {
            font-style: normal;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: $input-value-color;
          }
        }
      }
    }
  }
  .invoice--box {
    .invoice-tab__block {
      display: flex;
      justify-content: space-between;
      text-align: center;
      .invoice--text {
        display: flex;
        align-items: center;
        color: $input-value-color;
      }
    }
    .invoice-list--table {
      .invoice-list-icon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: #5f7384;
        cursor: pointer;
      }
      .invoice-vehicle-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.date-select-box--content {
  display: flex;
  .date-icon {
    margin: 0 1rem;
    cursor: pointer;
    > svg {
      color: #8d949b;
    }
    .billing-date-picker {
      margin-bottom: 1rem;
    }
  }
  .list-select--box {
    margin-left: 1.5rem;
    cursor: pointer;
    .react-select__control {
      border: 1px solid #8d949b;
      border-radius: 4px !important;
    }
    .list-select-box-label {
      font-style: normal;
      font-weight: normal;
      font-size: 0.813rem;
      color: #ffffff;
      line-height: 1rem;
      .select-input-form-group {
        margin-bottom: unset;
        .list-select-box-input {
          .css-qc6sy-singleValue,
          .css-14el2xx-placeholder {
            padding-left: 4rem;
            font-style: normal;
            font-weight: normal;
            font-size: 0.813rem;
            line-height: 1rem;
            color: rgba(255, 255, 255, 0.5);
          }
          .css-6j8wv5-Input {
            margin: 6px;
          }
          .css-6j8wv5-Input > button,
          input,
          optgroup,
          select,
          textarea {
            padding-left: 4rem !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-size: 0.813rem !important;
            line-height: 1rem !important;
            color: rgba(255, 255, 255, 0.5) !important;
          }
        }
      }
    }
  }
}
//Download-invoice
.invoice-page_main {
  .print--btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .invoice-page--wrapper {
    background-color: white;
    color: black;
    height: calc(100vh - 5.5rem);
    overflow-x: hidden;
    position: relative;
    width: 100%;
  }
  .iframe-class {
    height: 0;
    width: 0;
    position: absolute;
  }
}
