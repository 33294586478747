.session-heading__main {
  position: relative;
  width: auto;
  flex: 1;
  padding: 1rem 0 1.5rem 0;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #3d4e5f;
  &.heading-flex-box {
    display: flex;
    align-items: center;
  }
  .heading--content {
    display: flex;
    align-items: center;
    margin-right: 2rem;
    .heading--text {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;
      color: #ffffff;
    }
    .heading--number {
      margin-left: 0.4rem;
    }
  }
  .underline--highlight {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 3.5rem;
    border-bottom: 3px solid $primary-btn-color;
  }
  .session-heading--right {
    margin-left: auto;
  }
}
