.rfid-page-main {
  .rfid-form-card {
    .rfid-form__block {
      padding-bottom: 0.6rem;
    }
    .upload-file-block {
      .upload-file--input {
        display: none;
      }
      .upload-file-inner {
        cursor: pointer;
        .upload-file-text {
          font-style: normal;
          font-weight: bold;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: #e12f17;
          padding-left: 0.8rem;
        }
        .import-btn__block {
          .btn:focus {
            outline: none;
            box-shadow: none;
          }
          .import_btn {
            padding: 1rem 1.9rem;
            border-color: #223343;
            color: #e12f17;
            border-radius: 5px;
            background: #223343;
            .rfid-upload-file-box-icon {
              color: #e12f17;
              margin-right: 1rem;
            }
            .rfid-upload-file-box-text {
              font-style: normal;
              line-height: 1.25rem;
              color: #e12f17;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  .rfid-btn-block {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    .btn:focus {
      outline: none;
      box-shadow: none;
    }
    .rfid-btn {
      background: #223343;
      width: 17.5rem;
      height: 3.5rem;
      border-color: #223343;
      color: #e12f17;
      font-size: 0.9rem;
    }
  }
  .rfid-table-btn__block {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    .table_btn {
      width: 12.563rem;
      height: 3.5rem;
    }
  }
}

//rfid edit list css

.rfid-edit-list-page__main {
  .rfid-edit-list-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #2e3841;
    .rfid-edit-list-header-name {
      font-size: 1rem;
    }
    .close___btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: black;
      }
    }
  }
  .rfid-edit-list-body__block {
    padding: 1rem 2rem;
    .rfid-edit-list--block {
      .rfid-edit-list-table {
        .table-responsive {
          .record-list-table {
            tbody {
              tr {
                td {
                  background: #16212b;
                  border-right: 1px solid #29343e;
                  .form-input-group {
                    margin-bottom: 0rem;
                  }
                }
              }
            }
          }
          .rfid-edit-list-btn__block {
            margin-bottom: 1rem;
            display: flex;
            text-align: right;
            margin-top: 2rem;
            .rfid-edit-list_btn {
              padding: 0.8rem 1.7rem;
              font-weight: 500;
            }
            .cancel-button-block {
              margin-left: 0.5rem;
              .cancel____btn {
                margin-right: 1rem;
                background-color: transparent;
                padding: 0.8rem 1.7rem;
                border-color: transparent;
                color: $primary-btn-color;
                background-color: #36303a;
                &:focus {
                  box-shadow: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
