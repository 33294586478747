.forgot-page__main {
  .forgot-bg-block {
    background: #15212b;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .forgot-content-block {
    background: $primary-bg-color;
    width: 100%;
    max-width: 550px;
    margin: 1.875rem auto;
    padding: 1.875rem 2.5rem 3.125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .forgot-content--inner {
      color: $input-value-color;
      text-align: center;
      font-style: normal;
      .title {
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.5rem;
        letter-spacing: 1px;
        margin-bottom: 1.5rem;
      }
      .sub-title {
        font-weight: 300;
        font-size: 0.9rem;
        line-height: 1.75rem;
        letter-spacing: 1px;
        margin-bottom: 2rem;
      }
      .forgotPassword-form__block {
        width: 100%;
        .form-content-block {
          .phone-number-input__form-group {
            text-align: start;
            margin-bottom: 3rem;
          }
          .form-button--block {
            .send--btn {
              width: 100%;
              padding: 0.7rem;
              border-radius: 8px;
            }
          }
        }
      }
      .back-to-login--block {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 1rem;
        font-size: 0.95rem;
        font-weight: 700;
        a {
          text-decoration: none;
          color: #3c7cdd;
          display: inline-flex;
          align-items: center;
        }
        .back--icon {
          margin-right: 0.3rem;
          height: 1.1rem;
          width: 1.1rem;
        }
      }
    }

    .resetPassword-content--inner {
      color: $input-value-color;
      text-align: center;
      font-style: normal;
      .title {
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.5rem;
        letter-spacing: 1px;
        margin-bottom: 1.5rem;
      }
      .resetPassword-form__block {
        display: flex;
        flex-direction: column;
        text-align: start;
        justify-content: flex-start;
        width: 100%;
      }
      .form-content-block {
        .otp-input__main {
          .otp-inner-text {
            margin-bottom: 2rem;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            text-align: center;
          }
          .otp-input--box {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 2.5rem;
            position: relative;
            .otp-input-box--inner {
              margin-bottom: 1rem;
              .otp--box {
                width: 3.6rem !important;
                height: 3.5rem;
                background: #1a2a39;
                border: 1px solid #43525e;
                box-sizing: border-box;
                border-radius: 0.5rem;
                color: #ffffff;
              }
            }
          }
        }
        .form-button--block {
          margin-top: 1rem;
          .reset--btn {
            width: 100%;
            padding: 0.7rem;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
