.dataPicker-floating-label-input-group {
  display: flex;
  column-gap: 1.25rem;
  @media screen and (max-width: 768px) {
    column-gap: 2rem;
  }
  .input-field {
    max-width: 100%;
    width: 100%;
    margin-bottom: 0;
    @media screen and (max-width: 1024px) {
      width: 15rem;
    }
    @media screen and (max-width: 768px) {
      width: 13rem;
    }
    .datepicker-floating-label {
      font-style: normal;
      font-weight: normal;
      font-size: 0.813rem;
      color: #ffffff;
      line-height: 1rem;
    }
  }
  .react-datepicker-component .react-datepicker-input {
    border: none;
    height: unset;
    background: #182532;
  }
  .react-datepicker-component .react-datepicker-input input {
    background: #182532;
    border: 1px solid #1b2a38;
    box-sizing: border-box;
    padding: 1rem 3.8rem;
    font-size: 0.813rem;
    border-radius: unset !important;
    text-align: start;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.5);
    font-family: 'IBM Plex Sans', sans-serif;
    z-index: 1;
    outline: none;
    cursor: pointer;
  }
  .react-datepicker-component .react-datepicker-input:hover {
    background: none;
    border: none;
  }
  .react-datepicker-component .react-datepicker-input .button-wrapper {
    position: absolute;
    left: unset;
    top: 0;
    height: 100%;
    right: 0;
  }
  .react-datepicker-component .react-datepicker-input .button-wrapper .input-button {
    margin: 0 1.1rem;
  }
  .react-datepicker-component .react-datepicker-input.has-value input {
    background: #182532;
    border: 1px solid #1b2a38;
    box-sizing: border-box;
    border-radius: unset !important;
    padding: 1rem 3.8rem;
    font-size: 0.813rem;
    text-align: start;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.5);
    font-family: 'IBM Plex Sans', sans-serif;
    z-index: 1;
    outline: none;
    cursor: pointer;
  }
  .react-datepicker-component .react-datepicker-input.is-open {
    background: none;
    border: none;
  }
  .react-datepicker.floating {
    right: 0;
  }
  .react-datepicker {
    margin-left: 4.375rem;
    margin-top: 0.438rem;
    .react-datepicker-container {
      width: 274px;
      .react-datepicker-top {
        background: #565b6e;
        border: 1px solid #808080;
        &:after {
          border-bottom-color: #565b6e;
        }
        .display {
          opacity: 1;
          position: unset;
          z-index: unset;
          visibility: visible;
          pointer-events: visible;
        }
      }
      .react-datepicker-body {
        border-color: #808080 !important;
        .react-datepicker-row {
          border-color: #808080 !important;
          .react-datepicker-picker {
            color: #acacae !important;
            background: #39393e !important;
            cursor: pointer;
            text-decoration: none;
            font-weight: 400;
            border-color: #808080 !important;
          }
          .current {
            font-weight: 600;
            color: #e3e6e9 !important;
            background: #202028 !important;
          }
          .selected.current {
            color: #354052 !important;
            background: #bad7f2 !important;
          }
        }
      }
    }
  }
  .react-datepicker:not(.position-top) .react-datepicker-container .react-datepicker-top::after {
    border-bottom-color: #565b6e;
  }
}
