.oemList-page {
  .oemList-page-wrapper {
    .oemList-search-box {
      display: flex;
      align-items: center;
      .oemList-search-boxButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-bottom: 1rem;
        .oemList-search-box-Btn {
          padding: 0.7rem 2.6rem;
          margin-bottom: 0.5rem;
        }
      }
    }
    .oemList-table {
      .oemList-table-editIcon {
        margin-right: 1rem;
        font-size: 1.2rem;
        color: #3c7cdd;
        cursor: pointer;
      }
      .oemList-table-deleteIcon {
        font-size: 1.2rem;
        margin-right: 1rem;
        color: #be210b;
        cursor: pointer;
      }
      .oemList-eyeIcon {
        cursor: pointer;
        color: #ffffff;
      }
      .loader--block {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.dimension-data__block {
  white-space: unset !important;
  .dimension--data {
    max-width: 550px;
  }
}
//form css
.add-oem-page__main {
  .oem-form--card {
    .card-body {
      padding: 1.5rem 2.5rem;
    }
    .oem-form--block {
      padding: 1rem 0;
      .plug-block__field-inputs {
        .oem-plug--item {
          margin-bottom: 1.5rem;
          border-bottom: 1px solid #666666;
        }
        .field--btn {
          border-color: transparent;
          background-color: transparent;
          color: $primary-btn-color;
          font-size: 1rem;
          padding: 0;
          font-weight: 600;
          display: flex;
          align-items: center;
          &:focus,
          &:active {
            box-shadow: none;
          }
          .plus--icon {
            margin-right: 0.2rem;
          }
        }
      }
    }
    .form_btn__block {
      text-align: right;
      margin-top: 1rem;
      .form__btn {
        padding: 0.69rem 2rem;
        font-weight: 500;
        margin-right: 1rem;
      }
      .oem-cancel-btn--block {
        .oem-cancel--btn {
          margin-right: 1rem;
          background-color: transparent;
          border-color: transparent;
          background: #be210b;
          padding: 0.7rem 2rem;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
