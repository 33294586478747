.activity-item__main {
  padding: 1rem 0;
  .activity-item--inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 11px;
    .activity-icon--block {
      border: 1px solid #394f63;
      height: 50px;
      width: 50px;
      border-radius: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      padding: 0.3rem;
      margin-right: 1.5rem;
      .activity_icon__inner {
        position: relative;
        padding: 0.3rem;
        .activity--icon {
          color: #8d9dac;
          svg {
            height: 1.5rem;
            width: 1.5rem;
          }
        }
        .activity-status--icon {
          svg {
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
    .activity-content__main-block {
      flex: 1;
      width: 100%;
      // border-bottom: 1px solid #29343e;
      border-bottom: 1px solid #3b3e40;
      .activity-content--inner {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        .activity-details--content-block {
          display: flex;
          flex-direction: column;
          .activity-details-block {
            font-size: 0.875rem;
            font-weight: 400;
            margin-bottom: 0.5rem;
            .meta-info {
              color: #8d9dac;
            }
          }
          .activity-timing {
            color: #8d9dac;
            font-size: 0.875rem;
            font-weight: 400;
          }
        }
        .activity-item__right-block {
          margin-left: auto;
          color: #5f7384;
          .update-button {
            padding: 0.5rem 2.2rem;
          }
        }
      }
    }
  }
}
