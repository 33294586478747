.groupList-page {
  .groupList-header-text {
    padding: 1.5rem;
    font-size: 1.125rem;
  }
  .groupList-page-wrapper {
    .groupList-search-box {
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
      margin-top: 2rem;
      .groupList-search-boxButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-bottom: 1.6rem;
        .groupList-search-box-Btn {
          padding: 0.78rem 4rem;
        }
      }
    }
  }
}

// Form css
.group-form-page__main {
  .group-form-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #2e3841;
    .group-header-name {
      font-size: 1.25rem;
    }
    .close___btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: black;
      }
    }
  }
  .group-form-body__block {
    padding: 1rem 2rem;
    .group-form--block {
      .form-btn__block {
        text-align: right;
        margin-top: 1rem;
        .form_btn {
          padding: 0.8rem 1.7rem;
          font-weight: 500;
        }
      }
    }
  }
}
