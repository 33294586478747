.information__block {
  display: flex;
  .info-icon {
    margin-right: 1rem;
    padding: 1.2rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    svg {
      color: #3c7cdd;
      font-size: 1.3rem;
    }
  }
  .info--wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .info-title {
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
    }
    .info--text {
      .font-style {
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
      }
      .info-link--content {
        font-style: normal;
        text-decoration: none;
        font-weight: 400;
        font-size: 0.75rem;
        color: #f5604c;
        padding-left: 0.7rem;
        .info-link-icon {
          padding-left: 0.25rem;
        }
      }
    }
  }
}
