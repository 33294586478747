.table-responsive {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(195, 194, 197, 0.4);
  }
  .record-list-table {
    width: 100%;
    thead {
      tr {
        th {
          padding: 0.938rem 1.563rem;
          color: rgba(255, 255, 255, 0.5);
          background-color: $primary-bg-color;
          border-bottom: 1px solid #29343e;
          font-size: 0.875rem;
          font-weight: normal;
          white-space: nowrap;
          text-align: left;
          vertical-align: middle;
          transition: 0.3s ease-in-out;
          .sorting {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .ico {
              cursor: pointer;
              pointer-events: all;
              visibility: visible;
              opacity: 0;
              transition: all 0.3s ease-in-out;
              color: white;
            }
          }
          &:hover {
            background-color: #202028;
            .ico {
              pointer-events: all;
              visibility: visible;
              opacity: 1;
            }
          }
        }
        .action-col {
          width: 10%;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 20px 25px;
          color: $input-value-color;
          background-color: $primary-bg-color;
          font-size: 0.875rem;
          white-space: nowrap;
          border-bottom: 1px solid #29343e;
          text-align: left;
          vertical-align: middle;
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .record-list-table thead tr th {
    font-size: 0.813rem !important;
  }
  .record-list-table tbody tr td {
    font-size: 0.813rem !important;
  }
  .table-responsive {
    overflow: auto;
    max-width: 624px !important;
  }
}
@media only screen and (max-width: 1440px) {
  .table-responsive {
    overflow: auto;
    max-width: 1030px;
  }
}
@media only screen and (max-width: 1990px) and (min-width: 1450px) {
  .table-responsive {
    overflow: auto;
    max-width: 1510px;
  }
}
@media only screen and (max-width: 2225px) and (min-width: 2100px) {
  .table-responsive {
    overflow: auto;
    max-width: 1691px;
  }
}
@media (max-width: 768px) {
  .table-responsive {
    // max-width: 550px !important;
    overflow: auto;
  }
}
