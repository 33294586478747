.tenantAdminList-page {
  .tenantAdminList-page-wrapper {
    .tenantAdminList-search-box {
      display: flex;
      align-items: center;
      .tenantAdminList-search-boxButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-bottom: 1rem;
        .tenantAdminList-search-box-Btn {
          padding: 0.7rem 2.6rem;
        }
      }
    }
    .tenantAdminList-table {
      .tenantAdminList-table-editIcon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: #3c7cdd;
        cursor: pointer;
      }
      .tenantAdminList-lock-unlock-icon {
        color: lightskyblue;
        margin-right: 1rem;
        cursor: pointer;
      }
      .tenantAdminList-table-deleteIcon {
        color: #be210b;
        cursor: pointer;
      }
      .tenantAdminList-nameList {
        display: flex;
        align-items: center;
        .tenantAdminList-avatarList {
          height: 1.7rem;
          width: 1.7rem;
        }
        .user-info--name {
          text-decoration: none;
          color: inherit;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .loader--block {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
