.dashboard-details__main {
  .dashboard-details--inner {
    .dashboard-details__block {
      margin-bottom: 1rem;
    }

    .dashboard-table__main {
      .main-card-container {
        margin-bottom: 1.5rem;

        .dashboard-realTime_event_main {
          .dashboard-realTime_event_table {
            .realTime_event-header_name {
              border-bottom: 1px solid $react-pagination-hover-color;
              margin-bottom: 1rem;
              display: flex;
              justify-content: space-between;

              .dashboard-action--block {
                .dashboard-action--btn {
                  font-weight: 400;
                  font-size: 0.85rem;
                  padding: 0.5rem 1.7rem;
                  color: $primary-btn-color;
                  cursor: pointer;
                  text-decoration: none;

                  &:focus {
                    box-shadow: none;
                  }
                }
              }
            }

            .realTime_event-table {
              overflow-x: hidden;
              max-height: 350px;

              &.scrollable {
                overflow-y: auto;
              }
            }
          }
        }
      }

      .dashboard-energySold_chart_main {
        .dashboard-energySold_chart_inner {
          display: flex;
          margin-bottom: 1rem;

          .dashboard-energySold_chart_block {
            display: flex;
            flex-grow: 1;
            align-items: center;

            .dashboard-energySold_name {
              margin-right: 1.5rem;
            }

            .dashboard-energySold__btn {
              .dashboard-energySold-inner-btn {
                border: none;
                font-size: 0.85rem;
                background: $react-pagination-hover-color;
              }
            }
          }

          .dashboard-energySold_chart_content {
            display: flex;
            align-items: center;

            .dashboard-energySold__days {
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: $text-color-light;
              cursor: pointer;
              margin-right: 1rem;

              &.active {
                color: $input-value-color;
              }
            }

            .dashboard-energySold_days {
              margin-right: 1rem;
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: $text-color-light;
              cursor: pointer;

              &.active {
                color: $input-value-color;
              }
            }

            .dashboard-energySold_line {
              border: 1px solid $react-pagination-hover-color;
              height: 1rem;
              margin: 0 0.5rem;
            }

            .dashboard-energySold_filter_icon {
              display: flex;
              align-items: center;
              color: $text-color-light;

              svg {
                margin-left: 1rem;
              }

              .dashboard-energySold_filter_name {
                margin-left: 0.5rem;
              }
            }
          }
        }

        .dashboard-energySold_chart-img {
          .loader--block {
            height: 40vh;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .energySold_chart {
            height: auto;
            width: 100%;
          }
        }
      }

      .dashboard-bookingCount_chart_main {
        .dashboard-bookingCount_chart_inner {
          display: flex;
          margin-bottom: 1rem;

          .dashboard-bookingCount_chart_block {
            display: flex;
            flex-grow: 1;
            color: $input-value-color;
            align-items: center;

            .dashboard-energySold_name {
              margin-right: 1.5rem;
            }
          }

          .dashboard-bookingCount_chart_content {
            display: flex;
            align-items: center;

            .dashboard-bookingCount__days {
              font-style: normal;
              font-weight: normal;
              font-size: 0.875rem;
              line-height: 1.125rem;
              margin-right: 1rem;
              color: $text-color-light;
              cursor: pointer;

              &.active {
                color: $input-value-color;
              }
            }

            .dashboard-bookingCount_days {
              margin-right: 1rem;
              font-style: normal;
              font-weight: normal;
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: $text-color-light;
              cursor: pointer;

              &.active {
                color: $input-value-color;
              }
            }
          }
        }

        .dashboard-bookingCount_chart-img {
          .loader--block {
            height: 40vh;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .bookingCount_chart {
            height: auto;
            width: 100%;
          }
        }
      }

      .dashboard-totalUser_chart_main {
        .dashboard-totalUser_chart_inner {
          display: flex;
          margin-bottom: 1rem;

          .dashboard-totalUser_chart_block {
            display: flex;
            flex-grow: 1;
            color: $input-value-color;
            align-items: center;

            .dashboard-energySold_name {
              margin-right: 1.5rem;
            }
          }

          .dashboard-totalUser_chart_content {
            display: flex;
            align-items: center;

            .dashboard-totalUser__days {
              font-style: normal;
              font-weight: normal;
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: $text-color-light;
              cursor: pointer;
              margin-right: 1rem;

              &.active {
                color: $input-value-color;
              }
            }

            .dashboard-totalUser_days {
              margin-right: 1rem;
              font-style: normal;
              font-weight: normal;
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: $text-color-light;
              cursor: pointer;

              &.active {
                color: $input-value-color;
              }
            }
          }
        }

        .dashboard-totalUser_chart-img {
          .bookingCount_chart {
            height: auto;
            width: 100%;
          }
        }
      }

      .dashboard__sassClient__main {
        .dashboard__saasClient_card {
          width: 100%;
          border-bottom: 1px solid $react-pagination-hover-color;
          margin-bottom: 1rem;
        }

        .dashboard_inner_saasClient {
          .dashboard__top_name {
            margin-bottom: 1.5rem;
          }
        }
      }

      .dashboard-chart_main {
        .dashboard-chart_inner {
          .dashboard-chart_name {
            margin-bottom: 1.5rem;
          }

          .dashboard-chart-img_block {
            display: flex;
            justify-content: center;

            .loader--block {
              height: 39vh;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .dashboard-chart_img {
              width: 75%;
              height: 75%;
            }
          }
        }
      }

      .dashboard-recent--main {
        .dashboard-recent--inner {
          .dashboard-recent--block {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.5rem;

            .dashboard-recent--block_content {
              font-weight: 400;
              font-size: 0.85rem;
              color: $primary-btn-color;

              .charger-booking-link {
                cursor: pointer;
              }
            }
          }

          .dashboard-recent--table_block {
            max-height: 350px;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 6px;
              background-color: transparent;
            }

            &::-webkit-scrollbar-track {
              background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: $scrollbar-color;
            }
          }
        }
      }
    }
  }

  .loader--block {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

//Card design
.dashboard-card {
  background: $primary-bg-color;
  box-shadow: 0px 0px 10px $sub-table-body-row;
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  .dashboard-detail-box {
    display: flex;
    align-items: center;

    .dashboard-icon {
      margin-right: 1.5rem;

      .session-icon {
        background: $breating-area-blue;
        border-radius: 15px;
        height: 4rem;
        width: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 2rem;
          width: 2rem;
        }
      }

      .session-meta-icon {
        background: $breating-area-red;
        border-radius: 15px;
        height: 4rem;
        width: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 2rem;
          width: 2rem;
        }
      }
    }

    .dashboard__info {
      .title-session {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 0.2rem;
      }

      .info-text {
        font-size: 0.8rem;
        font-weight: 400;
      }
    }
  }
}

//realTime Event Table
.realTime-event-item__main {
  .realTime-event-item--inner {
    display: flex;
    align-items: baseline;

    .realTime-event-content__main-block {
      flex: 1 1;
      width: 100%;
      padding: 0 1rem;
      margin-bottom: 0.2rem;

      .realTime-event-content--inner {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $react-pagination-hover-color;
        padding: 1rem 0;

        .realTime-event-details--content-block {
          font-weight: 400;
          font-size: 12px;
          display: flex;
          align-items: center;
          flex: 1;

          .realTime-event-status--icon {
            margin: 0 0.7rem;
            color: $sidebar-menu-item-color;
          }

          .realTime-event-timing {
            color: $sidebar-menu-item-color;
          }
        }
      }
    }
  }
}

//saas client
.saasClient--item__main {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;

  .saasClient--item__inner {
    display: flex;
    width: 100%;
    align-items: center;

    .saasClient_img {
      height: 2rem;
      width: 2rem;
    }
  }

  .saasClient-content__main-block {
    display: flex;

    .saasClient---number {
      margin-right: 0.5rem;
    }

    .saasClient-details-icon {
      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}

//RecentTable component
.recentTable--item__main {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .recentTable--item__inner {
    .recentTable--item__inner_block {
      align-items: center;
      display: flex;

      .recentTable--item__img {
        .recentTable_img {
          height: 2rem;
          width: 2rem;
        }
      }

      .recentTable--item_content {
        .recentTable-text {
          margin-bottom: 0.1rem;
        }

        .recentTable--item__inner_content {
          display: flex;
          color: $text-color-light;

          .recentTable-details-icon {
            margin-right: 0.4rem;
          }
        }
      }
    }
  }

  .recentTable-content__block {
    .recentTable---date {
      margin-bottom: 0.1rem;
    }

    .recentTable---time {
      color: $text-color-light;
    }
  }
}
