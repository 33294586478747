.vehicle-management-page_main {
  .vehicle-management-card-block {
    margin-bottom: 0.5rem;
    .vehicle-card-block {
      cursor: pointer;
      &.active {
        border-bottom: 2px solid $primary-btn-color;
      }
    }
  }
  .vehicle-list-main-block {
    .vehicle-list-block {
      .vehicle-search-box {
        .add-vehicle-btn {
          padding: 0.8rem 2rem;
        }
        .excel-icon--block {
          padding-top: 0.6rem;
          .vehicle-excel-icon {
            cursor: pointer;
            .download-table-xls-button {
              color: #5dad5d;
              background: transparent;
              border: none;
              svg {
                height: 2rem;
              }
            }
          }
        }
      }
      .vehicle-list-table {
        .table-responsive {
          .record-list-table {
            .vehicle-name {
              .car-icon {
                margin-right: 0.9rem;
                svg {
                  height: 1.2rem;
                  width: 1.2rem;
                }
              }
            }
            .vehicle-moreInfo {
              color: #6f9de3;
            }
          }
        }
      }
    }
  }
}

.vehicle-card {
  .card-body {
    padding: 1rem;
    .vehicle-detail-box {
      display: flex;
      .vehicle-icon {
        position: relative;
        margin-right: 1rem;
        padding: 0.3rem;
        .car-icon {
          svg {
            height: 2.5rem;
            width: 2.5rem;
          }
        }
        .vehicle-status-icon {
          svg {
            position: absolute;
            bottom: 0rem;
            right: -0.5rem;
            height: 1rem;
            width: 1rem;
          }
        }
      }
      .vehicle__count-info {
        .total-vehicle {
          font-size: 1.25rem;
          font-weight: 600;
        }
        .info-text {
          font-size: 0.75rem;
          font-weight: 400;
        }
      }
    }
  }
}
