table {
  width: 100%;
}

th {
  padding: 20px 10px;
  color: #ffffff80 !important;
  font-size: 0.875rem;
  font-weight: normal;
  text-align: left;
  cursor: pointer;

  .sicon {
    float: right;
    color: aliceblue;
    visibility: hidden;
  }

  &:hover {
    background-color: rgb(24, 34, 44);

    .sicon {
      visibility: visible;
    }
  }
}

tr {
  border-bottom: solid 1px rgba(65, 65, 65, 0.418);
  text-align: left;
}

td {
  padding: 20px 10px;
  font-size: 0.875rem;
  text-align: left;
}

.pagination {
  padding: 5px 5px;
  font-size: 18px;
  float: right;
  margin-top: 5px;
}

.page_btn {
  padding: 0px 15px;
  margin: 0px 5px;
  background: $primary-bg-color;
  color: aliceblue;
  text-decoration: none;
}

.monthpicker {
  height: 60px;
  padding: 20px 10px;
  background-color: rgb(27, 42, 56);
  margin-right: 20px;
  border: none;
  outline: none;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;

  &::-webkit-calendar-picker-indicator {
    background-color: #3c7cdd;
    border-radius: 5px;
    cursor: pointer;
  }
}

.downloadbtn {
  background-color: rgba(255, 0, 0, 0);
  border: none;
  padding: 0px 20px;
  border-radius: 8px;
  margin-left: 10px;
}

.r_main {
  height: 500px;
  transition: 0.3s;

  @media only screen and (max-width: 1200px) {
    height: 300px;
    background-color: red;
  }

  .r_chart {
    width: 100%;
    padding: 5px;
    background: #1b2a38;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
    border-radius: 20px;
    padding: 1rem;
    margin-bottom: 0.5rem;
    position: relative;

    .custom_select {
      width: 8em;
      position: absolute;
      left: 0px;
      right: 0px;
      margin: auto;
      top: 40px;
      .react-select {
        .react-select__control {
          .react-select__value-container {
            padding: 0px 0.5rem;
          }
        }
      }
    }
  }

  tbody {
    overflow-x: scroll;
  }

  .page-content-wrapper {
    padding: 10px 0px;
  }
}
