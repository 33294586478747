.add-vehicle-page-main {
  .vehicle-form-card {
    .card-body {
      padding: 2rem 2.5rem;
    }
    .vehicle-form__block {
      padding-bottom: 2rem;
      .vehicle-form__inner {
        padding: 1rem 0;
      }
      .vehicle-save--btn--block {
        .vehicle-save-btn {
          padding: 1rem 1.5rem;
        }
      }
    }
  }
}
