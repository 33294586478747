.station-list-page__main {
  padding: 0.5rem 0rem;
  .search--block {
    margin-bottom: 1rem;
    display: flex;
    gap: 20px;
    .search-box__main {
      flex: 1;
      .form-input-group {
        margin-bottom: 0px;
      }
    }
    .addchargingstationbtn {
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        font-size: 18px;
      }
      span {
        display: block;
        @media only screen and (max-width: 424px) {
          display: none;
        }
      }
    }
  }
  .map-station--outer-block {
    display: flex;
    margin-top: 1.5em;
    @media only screen and (max-width: 599px) {
      flex-direction: column-reverse;
    }
    .station-list__block-main {
      margin-right: 1.5rem;
      width: 330px;
      @media only screen and (max-width: 599px) {
        width: 100%;
        margin-right: 0px;
        margin-top: 1.5em;
      }
      .station__count-info {
        display: flex;
        // background-color: ;
        border-radius: 20px;
        align-items: center;
        justify-content: space-between;
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        padding: 10px;
        .total-station {
          padding: 0.2rem 2rem;
          background-color: #1e3349;
          border-radius: 20px;
          font-size: 20px;
        }
      }
      .station-list__block-inner {
        height: calc(100vh - 18rem);
        overflow-y: hidden;

        &:hover {
          overflow-y: auto;
        }
      }
      .station-list-item__main {
        cursor: pointer;
        padding: 0.8rem;
        background: $primary-bg-color;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
        border-radius: 35px;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        position: relative;
        margin-right: 0.7rem;
        .station-item__inner {
          // width: 255px;
          display: flex;
          align-items: stretch;
          .station--icon-block {
            .station--icon {
              background: rgba(60, 124, 221, 0.1);
              border-radius: 25px;
              margin-right: 1rem;
              width: 100px;
              height: 100%;
              line-height: 100px;
              text-align: center;
              color: $info-icon-svg-color;
              font-size: 25px;
              font-weight: 700;
              display: flex;
              align-items: center;
              place-content: center;
              @media only screen and (max-width: 599px) {
                padding: 1rem;
              }
              svg {
                height: 1.5rem;
                width: 1.5rem;
                color: $info-icon-svg-color;
              }
            }
          }
          .station--info-block {
            width: 100%;
            flex: 1;
            .station--info_inner {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .station--name {
                font-size: 1rem;
                color: $input-value-color;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                margin-top: 1rem;
                margin-bottom: 0.5rem;
                width: auto;
                max-width: 15rem;
                margin-right: 1rem;
                text-transform: capitalize;
                @media only screen and (max-width: 599px) {
                  margin-top: 0rem;
                }
              }
              .station--rating-block {
                display: flex;
                align-items: center;
                margin-bottom: 0.2rem;
                .rating-star__block {
                  margin-right: 0.5rem;
                  // svg {
                  //   margin-right: 0.2rem;
                  // }
                }
                .rating__count {
                  color: $sidebar-menu-item-color;
                  font-weight: 600;
                  font-size: 0.9375rem;
                  line-height: 15px;
                }
              }
              .station--location-block {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $sidebar-menu-item-color;
                font-weight: 600;
                font-size: 0.9375rem;

                .location--name {
                  display: table;
                  svg {
                    display: table-cell;
                    margin-right: 10px;
                  }
                  span {
                    display: table-cell;
                  }
                }
                .tags--name {
                  display: table;
                  svg {
                    display: table-cell;
                    margin-right: 10px;
                  }
                  span {
                    display: table-cell;
                  }
                }
              }
            }
            .station-active__main {
              margin-top: 0.6rem;
              color: #e12f17;
              font-size: 0.75rem;
              font-weight: 500;
              text-align: right;
              .station-active-text {
                padding: 0.5rem;
                background: rgba(83, 71, 69, 0.1);
              }
            }
          }
        }
      }
    }
    .map__block-main {
      width: 100%;
      flex: 1;
      background-image: url('https://s3-alpha-sig.figma.com/img/85da/2fab/81f52953d795e83abf43fa2d1356d35f?Expires=1644796800&Signature=Yr4wmTGmtGmiGNNeQLUeJ8IezNN-oQtGptOtwEtU2EIuNPdS0iJxKRITsInMKZtbEsdT7oIlnMQbp3TNdPQdCrBjx7Ra-MFUd7PpogTDAe6mkNyEqsEROfg1t3lR2hWuoCOzzxkdp4FCKQucr8yFF~qccbS9yRBfuzmVflLIVjlaDSWJ83v3jsR2Lm67ygfFUFpNbIdtsCIggjgrX32qNfBYnQVSIPhhoWc9y7CdgJeFT3c-2kFaANDeLuZ1bxcBD2kRYNZqGYoXA9EpZuTydtm3YAX3a3XVCBoy-T5AC2ONyPqf~tDaOnOwuyhvcOgzavPzuVxk4tQDWnTptIY5mA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA');
      border-radius: 6px;
      @media only screen and (max-width: 599px) {
        flex: auto;
        height: 35vh;
      }
      .marker-text {
        cursor: pointer;
        color: black;
      }
    }
  }
  .loader--block {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.station-list-filter-canvas__container {
  background-color: $primary-bg-color;
  color: $input-value-color;
  .filter-canvas__inner {
    padding: 2rem;
    .canvas__header-block {
      padding: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #2e3841;
      .header-name {
        font-size: 1.25rem;
        font-weight: 500;
      }
      .header-close-icon {
        cursor: pointer;
      }
    }
    .canvas__body-block {
      padding: 1rem 0;
      &--inner {
        .form-select-label {
          font-size: 0.95rem;
          font-weight: 400;
          margin-bottom: 0.7rem;
        }
        .filter-select-box {
          margin-bottom: 1.5rem;
        }
        .canvas-selection__block {
          margin-bottom: 1.5rem;
          .selection-block__inner {
            border: 1px solid $input-border-color;
            border-radius: 10px;
            padding: 0.3rem;
            .row {
              margin: 0;
              .col {
                padding: 0;
              }
            }
            .selection--btn {
              padding: 0.7rem 0.5rem;
              font-size: 0.95rem;
              color: $text-color-light;
              text-align: center;
              cursor: pointer;
              border-radius: 10px;
            }
            .active {
              background-color: #be210b26;
              color: $input-value-color;
            }
          }
        }
        .canvas-btn__block {
          font-size: 0.95rem;
          .apply--btn {
            padding: 1rem 0.5rem;
            border-radius: 10px;
            margin-bottom: 1rem;
          }
          .reset--block {
            padding: 0.7rem;
            color: $text-color-light;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
              background-color: $breating-area-red;
            }
            .reset--icon {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #24000a;
  // background: #24000a;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 8px 0;
  background: #24000a;
  background: linear-gradient(0deg, #24000aaf 0%, #7909128e 48%, #e12f17 100%);
  // background-color: #e12f17;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 49px;
}
.ribbon-top-right::after {
  bottom: 49px;
  right: 0;
}
.ribbon-top-right span {
  left: -10px;
  top: 20px;
  transform: rotate(45deg);
}

.leaflet-container {
  width: 100%;
  height: 100vh;
  z-index: 1;
}
.leaflet-control-attribution.leaflet-control {
  display: none;
}
.custom-icon {
  background-color: transparent;
}
