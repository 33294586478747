.oem-modal-main_page {
  .oem-modal--inner {
    padding: 2rem 2.5rem;
    background: $primary-bg-color;
    .oem-modal---block_title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $input-value-color;
      font-style: normal;
      margin-bottom: 3rem;
      .inner--block_title {
        .title {
          font-weight: 500;
          font-size: 1.3rem;
          letter-spacing: 1px;
          margin-bottom: 0.5rem;
        }
        .sub-title {
          font-weight: 400;
          font-size: 0.95rem;
          letter-spacing: 1px;
          color: #ffffff80;
        }
      }
    }
    .oem-modal-input-box__block {
      .oem-model--btn {
        width: 12rem;
        text-align: left;
        padding: 0.7rem;
        &:focus {
          box-shadow: none;
        }
      }
      .cancel--btn {
        background-color: transparent;
        border-color: #a29f9f;
        text-align: center;
      }
      .remove--btn {
        text-align: center;
      }
    }
  }
}
