.phone-number-input__form-group {
  position: relative;
  margin-bottom: 1rem;
  width: auto;
  flex: 1;
  &.input-disabled {
    cursor: not-allowed;
  }
  .phone-number-input--label {
    font-size: 0.875rem;
    margin-bottom: 0.7rem;
    color: #ffffff80;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7;
    .require--star {
      color: #da1113;
      font-size: 0.95rem;
      margin-left: 3px;
    }
  }
  .phone-number-input--group {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: auto;
    box-sizing: border-box;
    border-radius: 0.5rem !important;
    border: 1px solid $input-border-color;
    &:focus-within {
      border-color: $input-focus-color !important;
    }
    .country-select-box {
      margin-bottom: 0 !important;
      .react-select {
        .react-select__control {
          border: none;
          .react-select__value-container {
            width: auto;
            max-width: 3.5rem;
          }
          .react-select__indicators {
            .react-select__indicator {
              padding: 0 0.2rem;
            }
          }
        }
        .react-select__menu {
          width: max-content;
        }
      }
    }
    .vertical--line {
      border-left: 2px solid #2f3c47;
      height: auto;
      margin: 0.5rem;
    }
    .phone-number--input-box {
      width: auto;
      flex: 1;
      border: none;
      color: $input-value-color;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      background: transparent;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      &:focus-visible {
        outline: none !important;
      }
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}
