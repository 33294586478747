.add-tenant-page__main {
  .tenant-form--card {
    .card-body {
      padding: 1.5rem 2.5rem;
    }
    .tenant-form--block {
      padding: 1rem 0;
      .gender-field__block {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .radio-field {
          margin-right: 3rem;
        }
      }
    }
    .add-cancel-btn-block {
      text-align: right;
      .add--btn {
        margin-right: 1rem;
        padding: 0.7rem 2rem;
      }
      .cancel-btn---block {
        margin-top: 2.5rem;
        .cancel---btn {
          margin-right: 1rem;
          background-color: transparent;
          border-color: transparent;
          background: #be210b;
          padding: 0.7rem 2rem;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
  .notification-setting__block {
    margin-top: 0.5rem;
    .notification--label {
      font-size: 0.95rem;
      margin-bottom: 0.7rem;
      color: $input-label-color;
    }
    .notification-setting--inner {
      .notification--item {
        float: left;
        margin-right: 3rem;
      }
    }
  }
  .tenant-form-page__main {
    .gst-block__field-inputs {
      .tenant-field--btn {
        border-color: transparent;
        background-color: transparent;
        color: #be210b;
        font-size: 1rem;
        padding: 0;
        font-weight: 600;
        display: flex;
        align-items: center;
        &:focus,
        &:active {
          box-shadow: none;
        }
        .plus--icon {
          margin-right: 0.2rem;
        }
      }
    }
  }
}
