.firmWareUpdate-page__main {
  .firmWareUpdate--version {
    margin-bottom: 1.5rem;
    .firmWareUpdate-version-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .firmWareUpdate-version-text {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        color: $input-value-color;
      }
    }
  }
  .firmWareUpdate-upload--file {
    margin-bottom: 1.5rem;
    .firmWareUpdate-upload-file-text {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
      margin-bottom: 2rem;
      color: $input-value-color;
    }
    .firmWareUpdate-upload-file-box {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.5rem;
      height: 8.7rem;
      background: rgba(190, 33, 11, 0.13);
      border: 1px dashed #be210b;
      box-sizing: border-box;
      border-radius: 8px;
      .firmWareUpdate-upload-file-box-wrapper {
        .firmWareUpdate-upload-file-box-content {
          cursor: pointer;
          .firmWareUpdate-upload-file-box-icon {
            color: #f5604c;
            margin-right: 1rem;
          }
          .firmWareUpdate-upload-file-box-text {
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.25rem;
            color: #f5604c;
          }
        }
      }
    }
    .firmWareUpdate-uploaded-file-list {
      .firmWareUpdate-uploaded-file-list-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        color: $input-value-color;
        background-color: $primary-bg-color;
        font-size: 0.875rem;
        white-space: nowrap;
        border-bottom: 1px solid #29343e;
        text-align: left;
        vertical-align: middle;
        .firmWareUpdate-uploaded--fileData {
          display: flex;
          align-items: center;
          .firmWareUpdate-uploaded--fileData > img,
          svg {
            font-size: 1.1rem !important;
          }
        }
        .firmWareUpdate-uploaded-file-list-text {
          margin-left: 1rem;
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: $input-value-color;
        }
        .firmWareUpdate-uploaded-file-list-closeBtn {
          padding: 0.5rem;
          font-size: 1.2rem;
          cursor: pointer;
          color: #be210b;
        }
      }
    }
    .firmWareUpdate--text {
      color: #dc4949;
      margin-top: 0.5rem;
      font-size: 0.75rem;
    }
    .firmWareUpdate-upload-file-btn {
      margin-top: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .firmWareUpdate-upload-file-btn-wrapper {
        padding: 0.9rem 2.4rem;
      }
    }
  }
  .firmWareUpdate--notification {
    .firmWareUpdate-notification-wrapper {
      .firmWareUpdate-notification-text {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        color: $input-value-color;
        margin-bottom: 1rem;
      }
      .firmWareUpdate-notification-table {
        overflow-x: hidden;
        max-height: 350px;
        &.scrollable {
          overflow-y: auto;
        }
        .firmWareUpdate-notification-table-revertIcon {
          margin-right: 2rem;
          cursor: pointer;
          font-style: normal;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.125rem;
          text-align: center;
          color: #f5604c;
        }
        .firmWareUpdate-notification-table-sendUpdateIcon {
          cursor: pointer;
          font-style: normal;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.125rem;
          text-align: center;
          color: #3c7cdd;
        }
        .firmWareUpdate-notification-nameList {
          display: flex;
          align-items: center;
          .firmWareUpdate-notification-avatarList {
            height: 23px;
            width: 23px;
          }
        }
      }
    }
  }
}
