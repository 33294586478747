$primary-bg-color: #1b2a38;
$secondary-bg-color: #223343;
$input-label-color: #ffffff80;
$input-border-color: #3b4a56; // custom-color
$input-value-color: #ffffff;
$input-disabled-color: #223444;
$input-focus-color: #979797;
$primary-btn-color: #be210b;
$secondary-btn-color: #36303a;
$secondary-btn-text: #f5604c;
$light-black-1: #182532;
$light-black-1-hover: #18253270;
$text-color-light: #8d949b;
$table-th-color: #ffffff80;
$page-content-wrapper: #15212b;
$sidebar-after-hover-background-color: #1e3349;
//new variables
$activity-icon-block-border: #394f63;
$primary-btn-color-hover: #bb2d3b;
$sidebar-menu-item-color: #8d9dac;
$scrollbar-color: #c3c2c566;
$info-icon-svg-color: #4975f2;
$react-pagination-detail-color: #5f7384;
$react-pagination-hover-color: #29343e;
$toggle-slider: #ccc;
$toggle-slider-checked: #0f9b72;
$tab-active-color: #1e3249;
$status-block-hover: #1b2a38ad;
$breating-area-blue: #3c7cdd1a;
$breating-area-red: #be210b21;
$excel-download-button: #0f9b72;
$admin-input-border: #808080;
$black-color: #000000;
$amenity-form-border-color: #1e3249;
$billing-info--box: #ffffff1a;
$charger-icon: #e85c481a;
$status_charger-yellow: #f2de10;
$sub-table-body-row: #60606021;
$rating-star: #fbaa26;
// $charger-status-sub-table-background:#141f29;

// Light Mode Colors
$light-primary-bg-color: #f0f0f0; // Change this to your light mode background color
$light-secondary-bg-color: #ffffff; // Change this to your light mode secondary background color
$light-input-label-color: #333333; // Change this to your light mode input label color
$light-input-border-color: #dddddd; // Change this to your light mode input border color
$light-input-value-color: #333333; // Change this to your light mode input value color
$light-input-disabled-color: #999999; // Change this to your light mode input disabled color
$light-input-focus-color: #979797; // Change this to your light mode input focus color
$light-primary-btn-color: #be210b; // Change this to your light mode primary button color
$light-secondary-btn-color: #f5604c; // Change this to your light mode secondary button color
$light-secondary-btn-text: #ffffff; // Change this to your light mode secondary button text color
$light-light-black-1: #f0f0f0;
$light-text-color-dark: #333333; // Change this to your light mode text color
$light-text-color-light: #8d949b;
$light-table-th-color: black;
$light-page-content-wrapper: #eef7ff;
$light-sidebar-hover-background-color: #f0faff;
$light-sidebar-after-hover-background-color: #eef7ff;
