.zoneList-page {
    .zoneList-page-wrapper {
      .zoneList-search-box {
        display: flex;
        align-items: center;
        .zoneList-search-boxButton {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          margin-bottom: 1rem;
          .zoneList-search-box-Btn {
            padding: 0.8rem 1rem;
            margin-bottom: 0.5rem;
          }
        }
      }
      .zoneList-table {
        .zoneList-table-editIcon {
          margin-right: 1.5rem;
          font-size: 1.2rem;
          color: #3c7cdd;
          cursor: pointer;
        }
        .zoneList-table-deleteIcon {
          font-size: 1.2rem;
          color: #be210b;
          cursor: pointer;
        }
        .loader--block {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  
  // Form css
  .zone-form-page__main {
    .zone-form-header__block {
      padding: 1rem 0;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #2e3841;
      .zone-header-name {
        font-size: 1.25rem;
      }
      .close___btn {
        padding: 0.1rem;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
          background-color: black;
        }
      }
    }
    .zone-form-body__block {
      padding: 1rem 2rem;
      .zone-form--block {
        .form-btn__block {
          text-align: right;
          margin-top: 1rem;
          .form_btn {
            padding: 0.8rem 1rem;
            font-weight: 500;
          }
          .cancel____btn {
            margin-right: 1rem;
            background-color: transparent;
            border-color: transparent;
            color: $primary-btn-color;
            &:hover {
              background-color: #36303a;
            }
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }
  