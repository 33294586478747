.otp-input-main__page {
  .otp-input--content {
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .otp-input--wrapper {
      .otp-input-block_title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
        border-bottom: 1px solid #2f3c47;
        .inner-block-title {
          margin-bottom: 1.5rem;
          .title {
            margin-bottom: 1.5rem;
          }
        }
      }
      .otp-input__box {
        .otp-input-text {
          margin-bottom: 2rem;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          text-align: center;
        }
        .otp-input-box--wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 2.5rem;
          position: relative;
          .otp-input-box__block {
            margin-bottom: 1rem;
            .otp--box {
              width: 3.6rem !important;
              height: 3.5rem;
              background: #1a2a39;
              border: 1px solid #43525e;
              box-sizing: border-box;
              border-radius: 0.5rem;
              color: #ffffff;
            }
          }
        }
      }
    }
    .otp--btn {
      display: flex;
      justify-content: center;
      .submit--btn {
        padding: 0.5rem 2.5rem;
        border-radius: 8px;
      }
    }
    .resend-otp__block {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2rem 0 0;
      .resend-otp__button {
        font-weight: 600;
        font-size: 0.95rem;
        line-height: 0.938rem;
        color: #3c7cdd;
        cursor: pointer;
        .resend--icon {
          height: 1.5rem;
          width: 1.5rem;
          margin-right: 0.3rem;
        }
      }
    }
  }
}
