.add-offer-page-main {
  .offer-form-card {
    .offer-form__block {
      padding-bottom: 2rem;

      .offer-form__inner {
        padding: 1rem 0;
      }

      .offer-save--btn--block {
        .offer-save-btn {
          padding: 1rem 1.5rem;
        }
      }
    }
  }
}
