.main-card-container {
  background: $primary-bg-color;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
  border-radius: 20px;
  color: #fff;
  margin-bottom: 15px;
  .card-body {
    padding: 1.5rem 2rem;
  }
}
