.dataTimePicker-floating-label-input-group {
  display: flex;
  column-gap: 1.25rem;
  @media screen and (max-width: 768px) {
    column-gap: 2rem;
  }
  .input-field {
    max-width: 100%;
    width: 100%;
    margin-bottom: 0;
    @media screen and (max-width: 1024px) {
      width: 15rem;
    }
    @media screen and (max-width: 768px) {
      width: 15rem;
    }
    .dateTimepicker-floating-label {
      font-style: normal;
      font-weight: normal;
      font-size: 0.813rem;
      color: $input-value-color;
      line-height: 1rem;
    }
  }

  .rdt input {
    background: $light-black-1;
    border: 1px solid $primary-bg-color;
    box-sizing: border-box;
    padding: 1rem 0.5rem 1rem 3.35rem;
    font-size: 0.813rem;
    border-radius: 15px;
    text-align: start;
    width: 100%;
    outline: none;
    font-style: normal;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.5);
    font-family: 'IBM Plex Sans', sans-serif;
    z-index: 1;
    outline: none;
    cursor: pointer;
    &:focus {
      border: none;
      box-shadow: unset;
    }
  }
  .rdtPicker {
    background: #1f252d;
    padding: unset;
    border-radius: 20px;
    overflow: hidden;
    border: 5px solid rgba(121, 121, 121, 0.096);
    box-shadow: 0px 0px 20px rgb(32, 32, 32);
    transition: 0.2s;
    .rdtDays {
      background: #1f252d;
    }
    .rdtTime {
      background: #1f252d;
    }
  }
}


.rdtPicker tr td{
  border: 3px solid #1f252d;
  padding: 12px;
  margin: 50px;
  border-radius: 8px;
  transition: 0.1s;
}

.rdtPicker tr td:hover{
  border: 3px solid #1f252d;
  margin: 50px;
  transition: 0.1s;
  background-color: rgb(255, 255, 255);
  color: black;
  font-weight: 600;
}

.rdtSwitch:hover
{
  color: black !important;
  font-weight: 600;
}

.rdtPrev:hover, .rdtNext:hover
{
  color: black !important;
}
