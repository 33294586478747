.station-details__main {
  .station-info--box {
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    //margin-bottom: 0.5rem;
    border-bottom: 1px solid #1e3249;
    padding-bottom: 22px;
    .station-info-content {
      .station-info__inner {
        display: flex;
        .station-icon--block {
          .station-icon {
            padding: 1.2rem 1.1rem;
            background: rgba(60, 124, 221, 0.1);
            border-radius: 15px;
            margin-right: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              height: 1.6rem;
              width: 1.8rem;
              color: $info-icon-svg-color;
            }
          }
        }
        .station-info--wrapper {
          .station-status--block {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            .info-name {
              display: flex;
              align-items: center;
              margin: 0.2rem;
              font-style: normal;
              font-weight: 500;
              font-size: 1.625rem;
              line-height: 2.063rem;
              color: $input-value-color;
            }
            .station-block-charger {
              display: flex;
              place-items: center;
              gap: 10px;
              .station-total-charger {
                border-radius: 20px;
                background: #1e3249;
                margin-top: 5px;
                padding: 5px 20px;
                font-size: 0.767rem;
              }
            }
            .station-status-block--inner {
              display: flex;
              align-items: center;
              flex-direction: column;
              margin-top: 5px;
              font-size: 0.767rem;
              .station-status {
                &.status-color-red {
                  color: #a5b6c6;
                  font-size: 1rem;
                  font-weight: 500;
                  .status-text {
                    font-size: 0.95rem;
                    padding-bottom: 1rem;
                  }
                }
                &.status-color-green {
                  color: #14ac7f;
                  font-size: 1rem;
                  font-weight: 500;
                  .status-text {
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
          .station-info-rating--block {
            display: flex;
            align-items: center;
            .rating-star-block {
              margin-right: 1rem;
              svg {
                margin-right: 0.2rem;
              }
            }
            .rating-count {
              font-weight: normal;
              font-style: normal;
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: $input-value-color;
            }
          }
        }
      }
    }
    .station-dropdown_btn {
      display: flex;
      align-items: center;
      gap: 10px;
      .has-dropdown {
        background: transparent;
        border: 0px;
        .dropdown-icon {
          background: #182532;
          border: 3px solid #182532;
          box-sizing: border-box;
          padding: 0.5em;
          border-radius: 12px;
          svg {
            color: #fff;
            font-size: 1.5rem;
          }
        }
        .dropdown-icon:hover {
          background: #000000;
          border: 3px solid #182532;
          box-sizing: border-box;
          padding: 0.5em;
          border-radius: 12px;
          svg {
            color: #fff;
            font-size: 1.5rem;
          }
        }
      }
      .station-info {
        background: #182532;
        border: 3px solid #182532;
        box-sizing: border-box;
        padding: 0.5em;
        border-radius: 12px;
        cursor: pointer;
        svg {
          color: #fff;
          font-size: 1.5rem;
        }
      }

      .station-info:hover{
        background: rgb(0, 0, 0);
        border: 3px solid #182532;
        box-sizing: border-box;
        padding: 0.5em;
        border-radius: 12px;
        cursor: pointer;
        svg {
          color: #fff;
          font-size: 1.5rem;
        }
      }
      

      .delete--station {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
          font-size: 1.2rem;
        }
        .delete-station-text {
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: #e12f17;
          padding-left: 1rem;
        }
      }

      .edit--station {
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
          font-size: 1.2rem;
        }

        .edit-station-text {
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: $input-value-color;
          padding-left: 1rem;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .station-status--block {
      .info-name {
        font-size: 1rem !important;
      }
    }
  }
  .tab-block {
    padding: 10px 0px;
    position: relative;
    .tab-nav-block {
      border-bottom: 0px;
    }
    .tab-content-block {
      .tab-content__item {
        padding-top: 0px;
        .consumption_div {
          place-items: center;
          .consumption_btn {
            display: flex;
            gap: 10px;
            div {
              margin-right: 1rem;
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: #8d949b;
              cursor: pointer;
            }
            div.active {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .loader--block {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
//general tab
.general-details__main {
  padding: 20px;
  .general-details--inner {
    .general-details__block {
      .general-details--wrapper {
        margin-bottom: 1.5rem;
        .general-stationdata-block {
          border-radius: 10px;
          background: #1e3249;
          display: flex;
          flex-direction: column;
          text-align: right;
          padding: 16px 10px 10px;
          .information-data {
            color: #fff;
            font-size: 1em;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .information-title {
            color: #8d949b;
            font-size: 0.7em;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }
  .general-info__main {
    margin-top: 1.5rem;
    .general-info__main-wrapper {
      .general-energySold_chart_main {
        .general-energySold_chart_inner {
          display: flex;
          margin-bottom: 1rem;

          .general-energySold_chart_block {
            display: flex;
            flex-grow: 1;
            // align-items: center;
            flex-direction: column;

            .general-energySold_name {
              margin-right: 1.5rem;
              color: #ffffff;
            }

            .general-energySold_subname {
              margin-right: 1.5rem;
              color: #a19898;
              font-size: small !important;
              font-weight: bold;
            }

            .general-energySold__btn {
              .general-energySold-inner-btn {
                border: none;
                font-size: 0.85rem;
                background: $react-pagination-hover-color;
              }
            }
          }

          .general-energySold_chart_content {
            display: flex;
            align-items: center;

            .general-energySold__days {
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: $text-color-light;
              cursor: pointer;
              margin-right: 1rem;

              &.active {
                color: $input-value-color;
              }
            }

            .general-energySold_days {
              margin-right: 1rem;
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: $text-color-light;
              cursor: pointer;

              &.active {
                color: $input-value-color;
              }
            }

            .general-energySold_line {
              border: 1px solid $react-pagination-hover-color;
              height: 1rem;
              margin: 0 0.5rem;
            }

            .general-energySold_filter_icon {
              display: flex;
              align-items: center;
              color: $text-color-light;

              svg {
                margin-left: 1rem;
              }

              .general-energySold_filter_name {
                margin-left: 0.5rem;
              }
            }
          }
        }

        .general-energySold_chart-img {
          .loader--block {
            height: 40vh;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
          }
          .energySold_chart {
            height: auto;
            width: 100%;
          }
        }
      }
      .operational-timing--block {
        .operational-timing-inner {
          margin-bottom: 1rem;
          .operation-timing-text {
            .timing-text-inner {
              color: $text-color-light;
              font-size: 1em;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
          .operation-timing__content {
            display: flex;
            align-items: center;
            .operation--time {
              .operation-time-text {
                font-size: 0.8em;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                color: $input-value-color;
              }
            }
          }
        }
      }
      .contact-person-details__main--inner {
        background: $primary-bg-color;
        border: 1px solid #2f3c47;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 0 0 1rem 0;
        padding: 1.5rem 1.2rem;
        .details-heading--block {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .details-heading {
            color: $text-color-light;
            margin-bottom: 1.5rem;
          }
        }
        .contact-info__main {
          .contact-info--block-item {
            margin-bottom: 0.5rem;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 0.95rem;
            .contact-info---icon {
              height: 1.2rem;
              width: 1.2rem;
              color: $sidebar-menu-item-color;
              margin-right: 0.875rem;
            }
            .info--label {
              margin-right: 0.7rem;
              color: rgba(255, 255, 255, 0.5);
            }
            .info--text {
              color: $input-value-color;
            }
          }
        }
      }
    }
    .address__box {
      .address__box--inner {
        border-radius: 10px;
        margin-bottom: 1rem;
        //padding: 1.5rem 1.2rem;
        .address-heading {
          color: $text-color-light;
          margin-bottom: 1rem;
        }
        .station-address {
          font-size: 0.95rem;
          color: $input-value-color;
          margin-bottom: 1rem;
        }
        .station-map-img {
          height: 29rem;
          width: 100%;
          .map-image {
            height: 100%;
            width: 100%;
            border-radius: 6px;
          }
          .station-marker-text {
            color: black;
          }
        }
      }
    }
  }
}
//chargers tab
.chargers-details__main {
  .charger-details--tabs {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 12px;
    gap: 6px;
    .charger-status-tab {
      padding: 0.7rem 1.5rem;
      font-style: normal;
      font-weight: normal;
      font-size: 0.8em;
      // line-height: 1.25rem;
      color: $text-color-light;
      margin-right: 0px;
      cursor: pointer;
      display: flex;
      &.active {
        background: rgba(60, 124, 221, 0.13);
        border-radius: 15px;
        padding: 0.7rem 1.5rem;
        color: $input-value-color;
        font-size: 0.8em;
        margin-right: 0px;
        font-style: normal;
        font-weight: normal;
      }
      .charger-count--badge {
        width: 21px;
        height: 21px;
        display: inline-block;
        padding: 4px;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 1rem;
        background: #0f9b72;
        margin-left: 0.5rem;
      }
      .draft-badge {
        background: $info-icon-svg-color;
      }
    }
    // .draft-tab {
    //   padding: 0.8rem 2.4rem 0.8rem 1.4rem;
    //   font-style: normal;
    //   font-weight: normal;
    //   font-size: 1rem;
    //   line-height: 1.25rem;
    //   color: #8d949b;
    //   margin-right: 1rem;
    //   cursor: pointer;
    //   &.active {
    //     background: rgba(60, 124, 221, 0.13);
    //     border-radius: 8px;
    //     padding: 0.8rem 2.4rem 0.8rem 1.4rem;
    //     color: $input-value-color;
    //     font-size: 0.875rem;
    //     margin-right: 1.125rem;
    //     font-style: normal;
    //     font-weight: normal;
    //   }
    //   .draft-badge {
    //     display: inline-block;
    //     padding: 0.4em 0.65em;
    //     font-size: 0.75rem;
    //     font-weight: 700;
    //     line-height: 1;
    //     color: #fff;
    //     text-align: center;
    //     white-space: nowrap;
    //     vertical-align: baseline;
    //     border-radius: 1rem;
    //     background: #3c7cdd;
    //     margin-left: 0.5rem;
    //   }
    // }
  }
  .charger-list--block {
    margin-top: 5px;
    .charger--list {
      background: $primary-bg-color;
      border: 1.023px solid #1e3249;
      box-sizing: border-box;
      border-radius: 20.453px;
      padding: 0.3rem 1.5rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: $light-black-1;
      }
      .list-info {
        cursor: pointer;
        margin-right: 1.5rem;
        flex: inherit;
        width: 10em;
        svg {
          font-size: 1.5rem;
        }
        .list-name {
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.25rem;
          color: $input-value-color;
          margin-left: 1rem;
        }
      }
      .make-info {
        cursor: pointer;
        margin-right: 0.5rem;
        // flex: inherit;
        display: flex;
        width: 10em;
        flex-direction: column;
        svg {
          font-size: 1.5rem;
        }
        .make-name {
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.25rem;
          color: $input-value-color;
          margin-left: 1rem;
          padding: 0px;
        }
        .model-name {
          font-style: normal;
          font-weight: 300;
          font-size: 0.8rem;
          line-height: 1.25rem;
          color: $input-value-color;
          margin-left: 1rem;
          padding: 0px;
        }
      }

      .charger-details--block {
        display: flex;
        flex: 1;
        place-items: center;
        .charger-status--block {
          display: inline-flex;
          align-items: center;
          margin-left: 1rem;
          .charger-details--status {
            display: flex;
            align-items: center;
            flex-direction: column;
            .disabled-list-text {
              color: #a5b6c6;
              font-style: normal;
              font-weight: 500;
              font-size: 0.9rem;
            }
            .list-dot-icon {
              display: inline-flex;
              font-size: 1.5rem;
            }
            .list-text {
              font-style: normal;
              font-weight: 500;
              font-size: 0.9rem;
            }
          }
        }
        .plug-info--block {
          margin-left: 1rem;
          // background-color: red;
          .plug-info {
            padding-right: 0.8rem;
            // background-color: yellow;
          }
          .plug-info-wrapper {
            .plug-info--inner {
              box-sizing: border-box;
              // background-color: #14ac7f;
              padding: 0.7rem 1rem;
              display: flex;
              align-items: center;
              margin-left: 10px;
              .plug-info-content {
                display: flex;
                align-items: center;
                margin-right: 1rem;
                width: auto;
                // background-color: yellow;
                margin-left: 2rem;
                .charger-round_icon {
                  margin-right: 0.7rem;
                  svg {
                    //color: $info-icon-svg-color;
                    transform: rotate(317deg);
                    height: 1.5rem;
                    width: 1.5rem;
                    stroke-width: 0.3;
                  }
                }
                .more-info
                {
                  // background-color: red;
                  .more-info-charger
                  {
                    background-color: #1e3249;
                    font-size: 0.9rem;
                    margin-left: 5px;
                    padding: 0.1rem 0.5rem;
                    border-radius: 5px;
                  }
                }
              }
            }
          }
        }
        .charger-meta-info {
          display: flex;
          justify-content: center;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          flex: 1;
          place-content: flex-end;
          gap: 10px;
          .copy-clipboard__block {
            .copy-btn {
              border: none;
              color: white;
              border-radius: 10px;
              padding: 10px;
              background: none;
              .copy--icon {
                height: 1.5rem;
                width: 1.5rem;
              }
              .copy--icon:hover {
                height: 1.5rem;
                width: 1.5rem;
                color: rgba(255, 255, 255, 0.581);
              }
            }
          }
          .qr-code--btn {
            padding: 5px;

            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            cursor: pointer;
          }
          .qr-code--btn:hover {
            color: rgba(255, 255, 255, 0.581);
          }
        }
      }
      .charger-list--button {
        margin-left: auto;
        .charger-action--btn {
          width: 7.5rem;
          border-radius: 5px;
          border: none;
          font-weight: 400;
          font-size: 0.85rem;
          padding: 0.7rem;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .charger-details--block {
      display: flex;
      flex-direction: column;
      .plug-info--block {
        margin-top: 1rem;
      }
    }
  }
}
.charger-status--info {
  .Available {
    svg {
      color: #0f9b72;
    }
  }
  .Charging {
    svg {
      color: #4975F2;
    }
  }
  .Faulted {  
    svg {
      color: #FF8B00;
    }
  }
  .Unavailable {
    svg {
      color: #BD210B;
    }
  }
  .text-size {
    font-size: 0.95rem;
    font-weight: 500;
  }
  .btn--blue {
    background-color: #3c7cdd21;
    color: #6f9de3;
  }
  .btn--red {
    background-color: #e85c4826;
    color: #f5604c;
  }
}

//reservations tab
.reservations-list--table {
  .list-icon {
    margin-right: 1.5rem;
    font-size: 1.2rem;
    color: $react-pagination-detail-color;
    cursor: pointer;
  }
  .reservations-vehicle-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .reservation--border {
    border-bottom-width: 0;
  }
  .reservations-name-list {
    display: flex;
    align-items: center;
    .list-avatar {
      height: 1.7rem;
      width: 1.7rem;
    }
  }
  .reservations-dropdown {
    .edit--block {
      display: flex;
      align-items: center;
      cursor: pointer;
      .edit-text {
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: $input-value-color;
        padding-left: 1rem;
      }
    }
    .delete--block {
      display: flex;
      align-items: center;
      cursor: pointer;
      .delete-text {
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: #e12f17;
        padding-left: 1rem;
      }
    }
  }
}
//stop Transaction
.stop-transaction_main {
  .stop-transaction-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #2e3841;
    .stop-transaction-name {
      font-size: 1.25rem;
    }
    .close-btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: black;
      }
    }
  }
  .stop-transaction_form {
    padding: 1rem 2rem;
    .stop-transaction_button {
      text-align: right;
      .stop-transaction-btn {
        padding: 0.8rem 1.7rem;
        font-weight: 500;
      }
      .cancel_btn {
        margin-right: 1rem;
        background-color: transparent;
        border-color: transparent;
        color: $primary-btn-color;
        &:hover {
          background-color: $secondary-btn-color;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

//review tab
.review-list__main {
  .review-list--block {
    margin-bottom: 1rem;
    .review-list-card {
      background: #213242;
      border-radius: 6px;
    }
    .review-list-card-wrapper {
      display: flex;
      align-items: flex-start;
      .review-avatar {
        height: 3.438rem;
        width: 3.438rem;
      }
      .review-list-content {
        display: flex;
        flex-direction: column;
        margin-left: 0.5rem;
        .review-list-name {
          font-style: normal;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.25rem;
          color: $input-value-color;
          margin-bottom: 0.5rem;
        }
        .review--rating-block {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;
          .review-rating-star__block {
            margin-right: 1rem;
            svg {
              margin-right: 0.2rem;
            }
          }
          .review-rating__count {
            color: #788a9b;
            font-weight: normal;
            font-size: 0.75rem;
            line-height: 0.938rem;
          }
        }
        .review-list-text {
          font-style: normal;
          font-weight: normal;
          font-size: 0.813rem;
          line-height: 1.125rem;
          color: $sidebar-menu-item-color;
        }
      }
    }
  }
}

//contact Person Form.
.contactPerson-form__main {
  .contactPerson-form-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #2e3841;
    .contactPerson-header-name {
      font-size: 1.25rem;
    }
    .close-btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: black;
      }
    }
  }
  .contactPerson-form-body__block {
    padding: 1rem 2rem;
    .contactPerson-form--block {
      .form_btn--block {
        text-align: right;
        margin-top: 1rem;
        .form--btn {
          padding: 0.8rem 1.7rem;
          font-weight: 500;
        }
        .cancel_btn {
          margin-right: 1rem;
          border-color: transparent;
          color: $primary-btn-color;
          background-color: $secondary-btn-color;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
