// userGroup list

.user-group-page_main {
  .user-group-main-block {
    .user-group-block {
      .user-group-search-box {
        .user-group-btn {
          padding: 0.8rem 2rem;
        }
      }
      .user-group-table {
        .table-responsive {
          .record-list-table {
            .user-group-icon {
              // color: lightskyblue;
              margin-right: 1rem;
              // cursor: pointer;
            }
            .user-group-table-editIcon {
              margin-right: 1.5rem;
              font-size: 1.2rem;
              color: #3c7cdd;
              cursor: pointer;
            }
            .user-group-table-deleteIcon {
              font-size: 1.2rem;
              color: #be210b;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

//userGroup Form

.user-group-form-page-main {
  .user-group-form-card {
    .card-body {
      padding: 2rem 2.5rem;
    }
    .user-group-form__block {
      padding-bottom: 2rem;
      .user-group-form__inner {
        padding: 1rem 0;
      }
      .user-group-form-save--btn--block {
        .user-group-form-save-btn {
          padding: 1rem 1.5rem;
        }
      }
    }
  }
}
