.amenityList-page {
  .amenityList-page-wrapper {
    .amenityList-search-box {
      display: flex;
      align-items: center;
      .amenityList-search-boxButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-bottom: 1rem;
        .amenityList-search-box-Btn {
          padding: 0.7rem 2.6rem;
        }
      }
    }
    .amenityList-table {
      .amenity-logo {
        height: 3.5rem;
        width: 3.5rem;
      }
      .amenityList-table-editIcon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: #3c7cdd;
        cursor: pointer;
      }
      .amenityList-table-deleteIcon {
        font-size: 1.2rem;
        color: #be210b;
        cursor: pointer;
      }
      .loader--block {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

// Form css
.amenity-form__main {
  .amenity-form-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #2e3841;
    .amenity-header-name {
      font-size: 1.25rem;
    }
    .close-btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: black;
      }
    }
  }
  .amenity-form-body__block {
    padding: 1rem 2rem;
    .amenity-form--block {
      .form_btn--block {
        text-align: right;
        margin-top: 1rem;
        .form--btn {
          padding: 0.8rem 1.7rem;
          font-weight: 500;
        }
        .cancel_btn {
          margin-right: 1rem;
          background-color: transparent;
          border-color: transparent;
          color: $primary-btn-color;
          &:hover {
            background-color: #36303a;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
