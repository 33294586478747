.switch__main {
  display: flex;
  align-items: center;
  .switch--label {
    display: flex;
    align-items: center;
    .switch--wrapper {
      display: flex;
      align-items: center;
    }
    .switch-text {
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: #ffffff;
      padding-left: 0.5rem;
    }
  }
}
