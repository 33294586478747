.subscriptions-page_main {
  .subscriptions-page__inner {
    padding: 1rem;
    .page-top--filter-header {
      .row > * {
        padding: 0 !important;
      }
    }
    .subscription-filter--box {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      .subscriptions-filter {
        background: #182532;
        border: 1px solid #1b2a38;
        box-sizing: border-box;
        padding: 0.75rem 1.3rem;
        cursor: pointer;
      }
    }
    .subscriptionList-search-boxButton {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin-bottom: 1.5rem;
      .subscriptionList-search-box-Btn {
        padding: 0.7rem 2.6rem;
      }
    }
    .subscription-list-table {
      margin-bottom: 1.5rem;
      .record-list-table {
        .subscription-name-list {
          display: flex;
          align-items: center;
          .user-info--name {
            cursor: pointer;
          }
          .subscription-avatar {
            height: 1.7rem;
            width: 1.7rem;
          }
        }
        .vehicle-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .list-icon {
            margin-right: 1.5rem;
            font-size: 1.2rem;
            color: #5f7384;
            cursor: pointer;
          }
        }
      }
    }
  }
}
