.sidebar-main {
  .react-sidebar__main {
    .pro-sidebar-inner {
      background: $primary-bg-color;
      .pro-sidebar-layout {
        overflow: hidden;
        .sidebar-header--block {
          height: 5.5rem;
          border-bottom: none;
          .sidebar__logo {
            display: flex;
            justify-content: center;
            padding: 0.5rem;
            height: 100%;
            .logo--img {
              cursor: pointer;
              height: auto;
              width: 12rem;
              img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                // filter: invert(1) brightness(100);
              }
            }
          }
          .small-sidebar-logo__block {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .small-logo--img {
              cursor: pointer;
              height: 3rem;
              width: 3rem;
              img {
                height: 100%;
                width: 100%;
              }
              svg {
                font-size: 2.5rem;
              }
            }
          }
        }
        .sidebar-content--block {
          height: calc(100% - 5.5rem - 57px);
          overflow: hidden;
          &:hover {
            overflow-y: visible;
          }
          .side-menu-bar {
            padding: 0.5rem 0.7rem;
            .sidebar-menu-item {
              color: $sidebar-menu-item-color;
              margin-bottom: 0.2rem;
              .pro-inner-item {
                padding: 0.7rem;
                border-radius: 15px;
                border-radius: 15px;
                font-size: 0.9rem;
                line-height: 2;
                font-weight: 400;
                font-weight: 400;
                &:hover {
                  background-color: $light-black-1;
                  color: $input-value-color;
                }
                .pro-icon-wrapper {
                  font-size: 1.2rem;
                  color: $input-value-color;
                }
              }
              &.active {
                .pro-inner-item {
                  color: $input-value-color;
                  background-color: $sidebar-after-hover-background-color !important;
                }
              }
            }
            .notification--number {
              background: #be210b;
              margin-left: 7px;
              padding: 2.5px 8.3px 2.5px 4px;
              border-radius: 1rem;
              font-size: 12px;
              font-weight: 400;
              color: white;
            }
            .sidebar-sub-menu-item {
              &.open {
                .pro-inner-item {
                  .pro-arrow-wrapper {
                    .pro-arrow {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
              .pro-inner-item {
                .pro-arrow-wrapper {
                  .pro-arrow {
                    width: 0;
                    height: 0;
                    border: 0;
                    padding: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid $sidebar-menu-item-color;
                    transform: unset;
                  }
                }
              }
              .pro-inner-list-item {
                background: inherit;
                padding-left: 1rem;
                ul {
                  padding: 0;
                }
                .popper-inner {
                  padding: 0.5rem;
                  margin-left: 10px;
                  border-radius: 20px;
                  transition: 0.2s;
                  .pro-menu-item {
                    .pro-inner-item {
                      padding-left: 1rem;
                    }
                  }
                }
                .pro-menu-item {
                  .pro-inner-item {
                    padding-left: 2rem;
                    .pro-item-content {
                      white-space: unset;
                    }
                  }
                }
              }
              .sub-menu-inner-menu-item {
                &.open {
                  .pro-inner-item {
                    .pro-arrow-wrapper {
                      .pro-arrow {
                        transform: rotate(180deg) !important;
                      }
                    }
                  }
                }
                .pro-inner-item {
                  padding-left: 0.53rem !important;
                }
                .pro-inner-list-item {
                  background: inherit;
                  padding-left: 1rem;
                  ul {
                    padding: 0;
                  }
                  li {
                    padding-left: 0.9rem;
                  }
                  .popper-inner {
                    padding: 1rem;
                    .pro-menu-item {
                      .pro-inner-item {
                        padding-left: 1.5rem !important;
                      }
                    }
                  }
                }
                .pro-inner-item {
                  .pro-arrow-wrapper {
                    .pro-arrow {
                      width: 0;
                      height: 0;
                      border: 0;
                      padding: 0;
                      border-left: 5px solid transparent;
                      border-right: 5px solid transparent;
                      border-top: 5px solid $sidebar-menu-item-color;
                      transform: unset !important;
                    }
                  }
                }
              }
            }
          }
        }
        .custom-span {
          cursor: pointer;
          padding: 0.5rem 1rem;
          border: 1px solid transparent;
          border-radius: 5px;
          transition: border-color 0.3s, box-shadow 0.3s;
          position: relative;
          overflow: hidden;
        }

        .chevron-icon {
          position: relative;
          z-index: 2; /* Ensure the icon is displayed above the red background */
        }

        .custom-span::before {
          content: '';
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: white;
          opacity: 0;
          transition: left 1s, opacity 0.3s;
        }

        .custom-span:hover::before {
          left: 0;
          opacity: 1;
        }

        .custom-span:hover {
          border-color: white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          color: black;
        }

        .sidebar-footer--block {
          &__inner {
            width: 100%;
            padding: 1rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            // cursor: pointer;
            &:hover {
              background-color: $light-black-1;
              // color: black;
            }
          }
        }
      }
    }
  }
}
.pro-sub-menu {
  ul {
    li.pro-sub-menu {
      .pro-inner-item {
        span.pro-item-content {
          padding-left: 1.47em;
        }
      }
    }
  }
}
