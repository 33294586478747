.datePicker-input--main {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 1rem;
  position: relative;
  .datePicker-input-label {
    font-size: 0.875rem;
    margin-bottom: 0.7rem;
    color: #ffffff80;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7;
    .require--star {
      color: #da1113;
      font-size: 0.95rem;
      margin-left: 3px;
    }
  }
  .datePicker-input-field {
    width: 100%;
    margin-bottom: 0;
    .datepicker-floating-label {
      font-style: normal;
      font-weight: normal;
      font-size: 0.813rem;
      color: #ffffff;
      line-height: 1rem;
    }

    .react-datepicker-component .react-datepicker-input {
      border: none;
      height: unset;
      background: transparent;
    }
    .react-datepicker-component .react-datepicker-input:hover {
      background: none;
      border: none;
    }
    .react-datepicker-component .react-datepicker-input input {
      pointer-events: none;
      box-sizing: border-box;
      text-align: start;
      width: 100%;
      color: rgba(255, 255, 255, 0.5);
      font-family: 'IBM Plex Sans', sans-serif;
      z-index: 1;
      outline: none;
      cursor: pointer;
      border: 1px solid #3b4a56 !important;
      color: #ffffff;
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.125rem;
      background: transparent !important;
      border-radius: 0.5rem !important;
      padding: 1rem;
    }
    .react-datepicker-component .react-datepicker-input .button-wrapper {
      position: absolute;
      left: unset;
      top: 0;
      height: 100%;
      right: 0;
    }

    .react-datepicker-component .react-datepicker-input .button-wrapper .input-button {
      margin: 0 1.1rem;
    }

    .react-datepicker-component .react-datepicker-input.has-value input {
      box-sizing: border-box;
      text-align: start;
      width: 100%;
      color: rgba(255, 255, 255, 0.5);
      font-family: 'IBM Plex Sans', sans-serif;
      z-index: 1;
      outline: none;
      cursor: pointer;
      border: 1px solid #3b4a56 !important;
      color: #ffffff;
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.125rem;
      background: transparent !important;
      border-radius: 0.5rem !important;
      padding: 1rem;
    }

    .react-datepicker-component .react-datepicker-input.is-open {
      background: none;
      border: none;
    }

    .react-datepicker {
      margin-left: 4.375rem;
      margin-top: 0.438rem;

      .react-datepicker-container {
        width: 274px;

        .react-datepicker-top {
          background: #565b6e;
          border: 1px solid #808080;

          &:after {
            border-bottom-color: #565b6e;
          }

          .display {
            opacity: 1;
            position: unset;
            z-index: unset;
            visibility: visible;
            pointer-events: visible;
          }
        }

        .react-datepicker-body {
          border-color: #808080 !important;

          .react-datepicker-row {
            border-color: #808080 !important;

            .react-datepicker-picker {
              color: #acacae !important;
              background: #39393e !important;
              cursor: pointer;
              text-decoration: none;
              font-weight: 400;
              border-color: #808080 !important;
            }

            .current {
              font-weight: 600;
              color: #e3e6e9 !important;
              background: #202028 !important;
            }

            .selected.current {
              color: #354052 !important;
              background: #bad7f2 !important;
            }
          }
        }
      }
    }
    .react-datepicker:not(.position-top) .react-datepicker-container .react-datepicker-top::after {
      border-bottom-color: #565b6e;
    }
  }
}
