.serverOperation_main_page {
  background: #15212b;
  .tab--block {
    padding: 20px 0;
    display: flex;
    .tab-nav--block {
      width: 297px;
      display: flex;
      flex-direction: column;
      background: #1b2a38;
      margin-left: 1rem;
      .tab-nav__item {
        border-bottom: 2px solid transparent;
        padding: 1rem 1.5rem;
        color: #8d949b;
        font-size: 0.9rem;
        font-weight: 500;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: #314456;
        }
      }
      .active {
        color: #ffffff;
        font-weight: 400;
        background: #be210b;
      }
      &__right {
        margin-left: auto;
      }
    }
    .tab-content-block {
      width: 100%;
      .tab-content___item {
        display: none;
        padding: 0 1.5rem;
      }
      .active {
        display: block;
      }
    }
  }
  @media (max-width: 1024px) {
    .tab--block .tab-nav-block .tab-nav__item {
      padding: 1rem 0;
    }
    .primary-button {
      padding: 0.4rem 0.8rem;
    }
  }
  @media (max-width: 768px) {
    .tab--block .tab-nav-block .tab-nav__item {
      margin: 0 0.5rem;
    }
    .primary-button {
      padding: 0.4rem 0.5rem;
    }
  }
}
