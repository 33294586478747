.header-container__main {
  padding: 0.7rem 1.7rem;
  height: 5.5rem;
  background: $primary-bg-color;
  display: flex;
  align-items: center;

  .header-container__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    width: auto;
    .header-left-content__block {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      .header-sidebar-toggle {
        display: inline-block;
        margin-right: 1rem;
        .header-logo--img {
          cursor: pointer;
          height: auto;
          width: 3rem;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .header-left-icon {
        display: inline-flex;
        margin-right: 1.5rem;
        opacity: 0.7;
        .back-to-link {
          text-decoration: none;
          color: inherit;
          &:hover {
            background: #15212b;
            font-weight: 600;
          }
          svg {
            font-size: 2.5rem;
          }
        }
        svg {
          font-size: 1.5rem;
        }
      }
      .header-page--title {
        font-size: 1.2rem;
        font-weight: 500;
        width: 18rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and (max-width: 1024px) {
          width: 13rem;
        }
      }
      .header-left--btn {
        margin-left: 1.2rem;
        .header--btn {
          background: #e12f17;
          padding: 0.5rem 1.7rem;
        }
        @media screen and (max-width: 1024px) {
          margin-left: 0.7rem;
          .header--btn {
            padding: 0.5rem 0.7rem;
          }
        }
      }
    }
    .header-right-content__block {
      display: inline-flex;
      align-items: center;
      .notification-icon {
        // opacity: 0.7;
        opacity: 1;
        margin-right: 1.5rem;
        position: relative;
        @media screen and (max-width: 768px) {
          margin-right: 0.5rem;
        }
        svg {
          font-size: 1.5rem;
        }
        .notification-list-menu {
          min-width: 425px !important;
          top: 40px !important;
          .notification-dropdown-list__main {
            width: 100%;
            border-bottom: 1px solid #bfd6ec;
            &.list--box {
              border-bottom: unset;
            }
            .list-item--inner {
              display: flex;
              .list-icon--block {
                border: 1px solid #394f63;
                height: 50px;
                width: 50px;
                border-radius: 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.2rem;
                padding: 0.3rem;
                margin-right: 1.5rem;
                .list_icon__inner {
                  position: relative;
                  padding: 0.3rem;
                  .list--icon {
                    color: #8d9dac;
                    svg {
                      height: 1.5rem;
                      width: 1.5rem;
                    }
                  }
                }
              }
              .list-content__main-block {
                flex: 1;
                width: 100%;
                border-bottom: 1px solid #29343e;
                .list-content--inner {
                  display: flex;
                  align-items: center;
                  margin-bottom: 1.5rem;
                  .list-details--content-block {
                    display: flex;
                    flex-direction: column;
                    .list-details-block {
                      font-size: 0.875rem;
                      font-weight: 400;
                      margin-bottom: 0.5rem;
                      .meta-info {
                        color: #8d9dac;
                      }
                    }
                    .list-timing {
                      color: #8d9dac;
                      font-size: 0.875rem;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .notification-count {
        background: #be210b;
        margin-left: 7px;
        padding: 1px 7.4px 1px 5.3px;
        border-radius: 1rem;
        font-size: 0.8rem;
        font-weight: 400;
        color: white;
        position: absolute;
        top: 0;
        text-align: center;
        left: 1.4rem;
      }
      .user-info-menu {
        top: 50px !important;
      }
      .user-info--block {
        display: flex;
        align-items: center;
        .user-info-icon {
          padding-right: 0.5rem;
        }
      }
      .user-info--block {
        display: inline-flex;
        align-items: center;
        .user__profile-pic {
          .avatar {
            height: 2.5rem;
            width: 2.5rem;
            margin-right: 1rem;
            @media screen and (max-width: 768px) {
              margin-right: 0.5rem;
            }
          }
        }
        .user__info {
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 6rem;
          .user--name {
            font-size: 0.95rem;
          }
          .user--role {
            font-size: 0.8rem;
            font-weight: 300;
            color: #c4c4c4;
          }
        }
        .arrow__icon {
          margin-left: 1rem;
          color: $primary-btn-color;
        }
      }
    }
  }
}
