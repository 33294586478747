.time-picker__main {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  .time-picker-label {
    font-size: 0.875rem;
    margin-bottom: 0.7rem;
    color: $input-label-color;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7;
  }

  .rc-time-picker-input {
    width: 100%;
    background-color: #1b2a38;
    border: 1px solid #3b4a56;
    border-radius: 10px !important;
    box-shadow: none !important;
    min-height: 32px;
    cursor: pointer;
    color: #ffffff;
    padding: 1.5rem;
    font-weight: 400;
    font-size: 0.875rem;

    &:focus-visible {
      border-color: unset;
    }
  }
  .rc-time-picker-clear {
    position: absolute;
    right: 14px;
    top: 16px;
    &-icon {
      &:after {
        width: 24px;
        height: 24px;
        font-size: unset;
        color: $primary-btn-color;
      }
      &:hover:after {
        color: $primary-btn-color;
      }
    }
  }
}
.rc-time-picker-panel {
  // min-width: 350px !important;
  width: auto !important;
  .rc-time-picker-panel-inner {
    background: #223343 !important;
    color: white !important;
    box-shadow: none !important;
    border: 1px solid $input-border-color !important;
    border-radius: 10px !important;
    margin-top: 4px !important;
    z-index: 99 !important;
    .rc-time-picker-panel-input-wrap {
      padding: 0.8rem;
      .rc-time-picker-panel-input {
        background: #223343 !important;
        color: white !important;
        font-weight: 400;
        font-size: 0.875rem !important;
      }
    }
    .rc-time-picker-panel-select {
      width: 115px;
      border: 1px solid #1b2a38;
      &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(195, 194, 197, 0.4);
      }
      li:hover {
        background: #1b2a38 !important;
      }
      .rc-time-picker-panel-select-option-selected {
        background: #394d60 !important;
      }
      @media screen and (max-width: 1440px) {
        width: 90px;
      }
      @media screen and (max-width: 1024px) {
        width: 56px;
      }
    }
  }
}
