.serverOperation-title-name {
  margin-bottom: 1.5rem;
}
.server-operation-table-page_main {
  .server-operation-table-main-block {
    .server-operation-table-block {
      .server-operation-search-box {
        .add-vehicle-btn {
          padding: 0.8rem 2rem;
        }
      }
      .server-operation-table_list {
        .table-responsive {
          .record-list-table {
            .edit-icon {
              margin-right: 1.5rem;
              font-size: 1.2rem;
              color: #3c7cdd;
              cursor: pointer;
            }
            .delete-icon {
              font-size: 1.2rem;
              color: #be210b;
              cursor: pointer;
            }
          }
        }
      }
      .loader--block {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
