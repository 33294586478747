.accordion-main {
  cursor: pointer;
  margin-bottom: 1rem;
  .accordion--block {
    .accordion-header {
      .accordion-header--block {
        display: flex;
        align-items: center;
        .accordion-icon {
          margin-right: 0.5rem;
        }
        .accordion-title {
          font-style: normal;
          font-weight: 400;
          font-size: 1rem;
          color: $input-value-color;
        }
      }
    }
    .accordion-button {
      background-color: $primary-bg-color;
      &:focus {
        border-color: unset;
        box-shadow: unset;
      }
    }
    .accordion-button::after {
      content: '';
      transition: 0.3s all;
      width: 12px;
      height: 12px;
      background: url('../../../svg/general/down-arrow-red.svg') no-repeat center;
      background-size: contain;
    }
    .accordion-item {
      border-color: $primary-bg-color;
      background-color: $primary-bg-color;
    }
  }
}
