.oemVendorList-page {
  .oemVendorList-page-wrapper {
    .oemVendorList-search-box {
      display: flex;
      align-items: center;
      .oemVendorList-search-boxButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-bottom: 1rem;
        .oemVendorList-search-box-Btn {
          padding: 0.7rem 2.6rem;
          margin-bottom: 0.5rem;
        }
      }
    }
    .oemVendorList-table {
      .oemVendorList-table-editIcon {
        margin-right: 1.5rem;
        font-size: 1.2rem;
        color: #3c7cdd;
        cursor: pointer;
      }
      .oemVendorList-table-deleteIcon {
        font-size: 1.2rem;
        color: #be210b;
        cursor: pointer;
      }
      .loader--block {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

// Form css
.oemVendor-form-page__main {
  .oemVendor-form-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #2e3841;
    .oemVendor-header-name {
      font-size: 1.25rem;
    }
    .close_btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: black;
      }
    }
  }
  .oemVendor-form-body__block {
    padding: 1rem 2rem;
    .oemVendor-form--block {
      .form_btn__block {
        text-align: right;
        margin-top: 1rem;
        .form__btn {
          padding: 0.8rem 1.7rem;
          font-weight: 500;
        }
        .cancel--btn {
          margin-right: 1rem;
          background-color: transparent;
          border-color: transparent;
          color: $primary-btn-color;
          &:hover {
            background-color: #36303a;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

//single OEM Modal
.chargeSpot-page__main {
  .chargeSpot-header__block {
    padding: 1rem 0;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #2e3841;
    .chargeSpot-header-name {
      font-size: 1.25rem;
    }
    .chargeSpot-close_btn {
      padding: 0.1rem;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: black;
      }
    }
  }
  .chargeSpot-body__block {
    margin-top: 1rem;
    .chargeSpot-inner--block {
      background: #1b2a38;
      border: 1px solid #2f3c47 !important;
      box-sizing: border-box;
      border-radius: 10px;
      .chargeSpot--header {
        background: #182532;
        border: 1px solid #1b2a38;
        box-sizing: border-box;
        border-radius: 8px 8px 0 0;
        padding: 1.5rem 1rem 1rem 1rem;
        border-bottom: 2px solid #2e3841;
        .chargeSpot-header-text {
          font-style: normal;
          font-weight: 600;
          font-size: 1.125rem;
          color: #ffffff;
        }
      }
      .chargeSpot-content {
        padding: 1rem;
        .chargeSpot-content-inner {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          .chargeSpot-content-text {
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;
            color: rgba(255, 255, 255, 0.5);
            min-width: 13rem;
          }
          .chargeSpot-content-detail {
            font-style: normal;
            font-weight: 300;
            font-size: 0.9rem;
            line-height: 18px;
            color: #ffffff;
            padding-left: 5rem;
          }
        }
      }
    }
  }
  .chargeSpot-connectors__block {
    margin-top: 1rem;
    .chargeSpot-heading {
      margin-bottom: 1rem;
      font-style: normal;
      font-weight: 600;
      font-size: 1.125rem;
      color: #ffffff;
    }
    .ac-connector-table {
      margin-top: 1rem;
    }
    .dc-connector-table {
      margin-top: 1rem;
    }
    .empty__data {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.9rem;
    }
  }
  .chargeSpot-close-button-box {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .chargeSpot-close-button {
      padding: 0.8rem 1.9rem;
    }
  }
}
