.tenantAdminDetails-page {
  .tenantAdmin-profile {
    margin-bottom: 1.5rem;
    .tenantAdmin-profile-content {
      display: flex;
      flex-wrap: wrap;

      .tenantAdmin-profile-content-wrapper {
        flex-grow: 1;
        margin-left: 0.5rem;

        .tenantAdmin-profileInfo {
          display: flex;
          align-items: flex-start;
          margin-bottom: 1rem;
          flex-wrap: wrap;
          justify-content: space-between;
          .tenantAdmin-profileInfo-wrapper {
            display: flex;
            flex-direction: column;
            .tenantAdmin-profile-name {
              display: flex;
              align-items: center;
              margin-bottom: 0.8rem;
              font-size: 1.625rem;
              font-weight: 500;
              line-height: 2.063rem;
            }
            .tenantAdmin-name {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 1rem;
              padding-right: 0.5rem;
              font-style: normal;
              font-weight: normal;
              font-size: 0.875rem;
              line-height: 1.125rem;
            }
          }
          .delete--tenant {
            display: flex;
            align-items: center;
            cursor: pointer;
            svg {
              font-size: 1.2rem;
            }
            .delete-tenant-text {
              font-style: normal;
              font-weight: normal;
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: #e12f17;
              padding-left: 1rem;
            }
          }
        }

        .tenantAdmin-profileContact {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .tenantAdmin-profileContact-wrapper {
            display: flex;
            flex-grow: 1;
            padding-right: 1rem;
            .tenantAdmin-emailName {
              font-style: normal;
              font-weight: 400;
              font-size: 1rem;
              line-height: 1.25;
              padding-left: 1rem;
            }
            .tenantAdmin-contactNumber {
              font-style: normal;
              font-weight: 400;
              font-size: 1rem;
              line-height: 1.25;
              padding-left: 1rem;
            }
          }
          .tenantAdmin-profile-btn {
            display: flex;
            align-items: center;
            flex-direction: column;
          }
        }
      }
    }
  }
  .tenantAdmin-CompanyInfo {
    margin-bottom: 1.5rem;
    .tenantAdmin-CompanyInfo-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tenantAdmin-CompanyInfo-content-wrapper {
        margin-bottom: 1.5rem;
      }
    }
  }
  .tenantAdmin-Subscription-details {
    margin-bottom: 1.5rem;
    .tenantAdmin-Subscription-details-wrapper {
      margin-bottom: 1rem;
      .tenantAdmin-Subscription-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tenantAdmin-Subscription-wrapper {
          display: flex;
          margin-bottom: 1rem;
          .tenantAdmin-proSubscription {
            // background: #c79f32;
            // border-radius: 4px;
            padding: 0.1rem 0.75rem;
            text-align: center;
            margin-right: 1rem;
          }
          .tenantAdmin-Subscription-Plan {
            display: flex;
            align-items: center;
            .tenantAdmin-Subscription-planText {
              font-style: normal;
              font-weight: 600;
              font-size: 0.9rem;
              line-height: 1.25rem;
              color: $input-value-color;
            }
            .tenantAdmin-Subscription-renewText {
              font-style: normal;
              font-weight: 500;
              font-size: 0.9rem;
              color: #8d9dac;
            }
          }
        }
        .tenantAdmin-Subscription-offerContent {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-bottom: 1rem;
          .tenantAdmin-Subscription-offerContent-link {
            margin-right: 1.5rem;
            text-decoration: none;
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.125rem;
            text-align: center;
            color: #3c7cdd;
          }
          .tenantAdmin-Subscription-offerContent-btn {
            margin-left: 1rem;
            .tenantAdmin-Subscription-offerContent-cancelSubscriptionBtn {
              background: rgba(190, 33, 11, 0.25);
              border: navajowhite;
              color: #f5604c;
            }
          }
        }
        @media (max-width: 1024px) {
          .tenantAdmin-Subscription-offerContent {
            justify-content: flex-start !important;
          }
        }
      }
    }
    .tenantAdmin-Invoices-wrapper {
      .tenantAdmin-Invoices-link {
        text-decoration: none;
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.125rem;
        text-align: center;
        color: #3c7cdd;
      }
    }
  }
  .tenantAdmin-CSMS {
    margin-bottom: 1.5rem;
    .tenantAdmin-CSMS-text {
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.25rem;
      color: $input-value-color;
      padding-right: 1rem;
    }
    .tenantAdmin-CSMS-timing {
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.125;
      color: $input-value-color;
    }
  }
}

.invoice__item-main {
  .invoice-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    color: $input-value-color;
    background-color: $primary-bg-color;
    font-size: 0.875rem;
    white-space: nowrap;
    border-bottom: 1px solid #29343e;
    text-align: left;
    vertical-align: middle;
    .invoice-detail--info {
      display: flex;
      align-items: center;
      .invoice-pdf-icon {
        display: inline-flex;
        margin-right: 1rem;
        svg {
          height: 1rem;
          width: 1rem;
        }
      }
      .invoice-file-name {
        font-weight: 300;
        font-size: 0.9rem;
        color: $input-value-color;
      }
    }
    .download-icon {
      cursor: pointer;
      svg {
        color: #3c7cdd;
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}
