.footer-position {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #14212b;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 99%;
  display: flex;
  justify-content: flex-end;
  z-index: 99;
}
